<template>
  <div class="plan-wrapper">
    <template v-if="!sent">
      <ContactForm :on-submit="submitContact" :error="error" :subscriber-email="email" :subscriber-name="name" :subscriber-phone="phone" />
    </template>
    <div v-if="sent" class="plan-panel">
      <h2>{{t('Message sent')}}</h2>
      <p>{{t('We will get back to you shortly.')}}</p>
    </div>
  </div>

</template>

<script>
import t from '@/lib/Locale';
import ContactForm from "@/components/forms/ContactForm";
import Fito from "@/lib/fito";

export default {
  name: "ContactUs",
  components: {ContactForm, },

  data() {
    return {
      error : '',
      blocked: false,
      sent: false,

      name : null,
      email: null,
      phone: null,

      init: false,
      login : {}
    }
  },

  async mounted() {
    this.login = await Fito().Login.CheckLogin();
    this.loadInfo()

  },

  methods: {
    t,

    async submitContact(email, phone, name, subject, message) {
      if (this.blocked) return false;
      this.error = '';
      this.blocked = true;
      const r = await Fito().Contact.SendContactForm(email, phone, name, subject, message);

      this.blocked = false;

      if (r.res === 'ok') {
        this.sent = true;

        setTimeout(() => this.$router.push(this.login.subscriber?'/plan/home':'/'), 4000)

      }
      else if (r.error) {
        this.error = r.error;
      }
    },

    loadInfo() {
      if (this.login.subscriber) {
        const {subscriber} = this.login;
        if (subscriber.email) this.email = subscriber.email;
        if (subscriber.phone) this.phone = subscriber.phone;
        if (subscriber['first_name'] && subscriber['last_name']) this.name = subscriber['first_name'] + ' '+subscriber['last_name'];
      }
    },
  },

}
</script>
