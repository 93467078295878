<template>
  <div class="loading-results">
    <div class="loading-results-inner">
      <div class="loading-results-logo"></div>
      <p>{{ t('Creating your personalized nutrition plan...') }}</p>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale";
export default {
  name: "LoadingResults",
  methods : {
    t,
  }
}
</script>
