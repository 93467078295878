<template>
  <div class="results-view">
    <div class="screen results-intro">
      <div class="intro-logo">
        <router-link to="/"><img :src="Logo" /></router-link>
      </div>
      <div class="container-m">
        <h2>{{t('intro-title')}}</h2>
        <p>{{t('intro-text')}}</p>
        <router-link to="/plan/signup" class="btn active radiant">{{t("Join Now")}} <img :src="leftArrowIcon" class="arrow-icon" /></router-link>
      </div>
      <div class="intro-scroll">
        <img :src="ScrollDown" />
      </div>
    </div>

    <div class="screen results-features">
      <div class="features-inner">
        <div class="rounded-label">{{t('About the Plan')}}</div>
        <Vimeo :path="783412392" />
        <h2>{{t('intro-title-features')}}</h2>
        <div class="intro-features-video"></div>
        <div class="results-feature">
          <div class="feature-icon"><img :src="SettingsIcon" /></div>
          <h3>{{t('feature1-title')}}</h3>
          <p>{{t('feature1-text')}}</p>
        </div>
        <div class="results-feature">
          <div class="feature-icon"><img :src="StrengthIcon" /></div>
          <h3>{{t('feature2-title')}}</h3>
          <p>{{t('feature2-text')}}</p>
        </div>
        <div class="results-feature">
          <div class="feature-icon"><img :src="FlashIcon" /></div>
          <h3>{{t('feature3-title')}}</h3>
          <p>{{t('feature3-text')}}</p>
        </div>
      </div>
    </div>

    <div class="screen results-how-it-works">
      <div class="raised">
        <div class="rounded-label">{{t('How it Works')}}</div>
        <h2>{{t('intro-title-how-it-works')}}</h2>

        <div class="howitworks-item">
          <h3>{{t('how-it-works1-title')}}</h3>
          <p>{{t('how-it-works1-text')}}</p>
        </div>

        <div class="howitworks-item">
          <h3>{{t('how-it-works2-title')}}</h3>
          <p>{{t('how-it-works2-text')}}</p>
        </div>

        <div class="howitworks-item">
          <h3>{{t('how-it-works3-title')}}</h3>
          <p>{{t('how-it-works3-text')}}</p>
          <Vimeo path="776690714" />
        </div>



        <div class="intro-how-it-works-video"></div>
      </div>
    </div>

    <div class="screen results-faq">
      <div class="padding">
        <div class="rounded-label">{{t('Frequently Asked Questions')}}</div>
        <h2>{{t('intro-title-faq')}}</h2>
        <div class="faq-item" v-for="(item, ii) in QAData" :key="ii">
          <div class="faq-header" @click="() => toggleFaqItem(ii)">
            <h3>{{item.question}}</h3>
            <img :src="faqOpen[ii]?minusIcon:plusIcon" />
          </div>
          <p v-if="faqOpen[ii]">{{item.answer}}</p>
        </div>
      </div>
    </div>

    <div class="screen results-testimonials" v-if="2<1">
      <div class="padding">
        <h2>{{t('testimony-text-1')}}</h2>
        <img class="testimonials-avatar" :src="Avatar" />
        <h4>{{t('testimony-name-1')}}</h4>
        <p>{{t('testimony-details-1')}}</p>
        <div class="testimonials-score"><img :src="Star" v-for="item in 5" :key="item" /></div>
      </div>
    </div>

    <div class="screen results-join-now">
      <div class="padding">
        <h2>{{t('intro-title-join-now')}}</h2>
        <router-link class="btn active radiant" to="/plan/signup">{{t('Join Now')}} <img :src="leftArrowIcon" class="arrow-icon" /></router-link>
        <div class="join-now-footer">
          <img :src="Logo" />

          <p class="copy">{{t('copyright-text')}}</p>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
import t from '@/lib/Locale';
import leftArrowIcon from '@/assets/icons/arrow-left.svg';
import FlashIcon from '@/assets/icons/results/flash.svg';
import SettingsIcon from '@/assets/icons/results/settings.svg';
import StrengthIcon from '@/assets/icons/results/strength.svg';
import Star from '@/assets/icons/results/star.svg';
import plusIcon from '@/assets/icons/plus-icon.svg';
import minusIcon from '@/assets/icons/minus-icon.svg';
import ScrollDown from '@/assets/icons/scroll-down.svg';
import Avatar from '@/assets/images/results/avatar.png';
import Logo from '@/assets/images/logo.svg';
import QAData from "@/lib/docs/QAData";
import Vimeo from "@/components/ui/widgets/Vimeo";

export default {
  name: "Results",
  components: {Vimeo},
  props: ['stats','invoice'],
  data() {
    return {
      faqOpen: [true],
      QAData,
      Logo,
      leftArrowIcon,
      FlashIcon,
      Star,
      SettingsIcon,
      plusIcon, minusIcon,
      Avatar,
      ScrollDown,
      StrengthIcon
    }
  },


  mounted() {
    if (localStorage.getItem('login-token')) {
      window.location.href = '/plan/home';
      return false;
    }

    if (!localStorage.getItem('QuizAnswers')) {
      window.location.href = '/quiz';
      return false;
    }

  },

  methods: {
    t,
    toggleFaqItem(i) {
      this.$set(this.faqOpen, i, !this.faqOpen[i])
    },
    scrollToPurchase() {
      window.scrollTo({
        top: document.querySelector('#purchase-plan').offsetTop,
        behavior: 'smooth'
      });
    },

  },
  computed : {
  }
}
</script>
