<template>
  <div class="bottom-navbar">
    <div class="bottom-navbar-inner">
      <router-link to="/plan/home"><img :src="HomeIcon" /></router-link>
      <router-link to="/plan/weekly-plan"><img :src="MenuIcon" /></router-link>
      <router-link to="/plan/profile"><img :src="ProfileIcon" /></router-link>
      <router-link to="/plan/library"><img :src="HelpIcon" /></router-link>
    </div>
  </div>
</template>

<script>

import HomeIcon from '@/assets/icons/plan/menu-home.svg';
import MenuIcon from '@/assets/icons/plan/menu-calendar.svg';
import ProfileIcon from '@/assets/icons/plan/menu-profile.svg';
import HelpIcon from '@/assets/icons/plan/menu-help.svg';

export default {
  name: "BottomNavbar",
  data() {
    return {
      HomeIcon,
      MenuIcon,
      ProfileIcon,
      HelpIcon,
    }
  }
}
</script>
