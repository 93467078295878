import {ApiRequest, ApiCall} from '../lib/Api';
import ApiRoutes from "../lib/ApiRoutes";
import Fito from "@/lib/fito";

const cache = {}, RECHECK_LOGIN_EVERY_SECS = 300
let lastLoginTime = null

function toSecs(time) {
    return Math.round(time/1000)
}

export default class login {

    constructor(fito) {
        this.fito = fito;
        this.loadToken()
        this.callbacks = { login: [], logout: [] }
    }

    loadToken() {
        const token = localStorage.getItem('login-token')
        if (token) this.fito.token = token;
    }

    saveToken(token) {
        this.fito.token = token;
        return localStorage.setItem('login-token', token)
    }

    onLogin(callback) {
        this.callbacks.login.push(callback)
    }

    onLogout(callback) {
        delete cache.login
        this.callbacks.logout.push(callback)
    }

    async safeCheckLogin(force = false) {
        let loginAttempt
        try {
            loginAttempt = await Fito().Login.CheckLogin(force);
        } catch (e) {
            return false
        }
        return loginAttempt
    }

    async CheckLogin(force = false) {
        if (!this.fito.inited) return false
        this.loadToken()
        if (cache.login && !force) {
            let time = toSecs(new Date().getTime())
            if (lastLoginTime && time - lastLoginTime < RECHECK_LOGIN_EVERY_SECS) { // Allow 5m cache for login
                return cache.login
            }
        }
        try {

        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.checkLogin, {token: this.fito.token}), this.fito.ApiKey, this.fito.token)
        this.fito.loggedIn = false
        if (r.status === "error") throw r.error
        if (r.status === "notfound") return false
        if (r.status === "expired") this.fito.expired = true;
        if (r.suspended) this.fito.suspended = r.suspended;
        this.fito.loggedIn = true
        for (let cb of this.callbacks.login) cb(r)

        cache.login = r
        lastLoginTime = toSecs(new Date().getTime())

        return r;
        }
        catch (e) {
            console.log("TF",e)
            return false
        }
    }

    async Login(email, password, externalId = '') {
        delete cache.login
        let body = {email, password};
        if (externalId) body.external_id = externalId;
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.login, body), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        if (r.token) {
            this.saveToken(r.token);
            this.fito.loggedIn = true;
            for (let cb of this.callbacks.login) cb(r, true)
            this.fito.Plan.ResetPlanCache()

            lastLoginTime = toSecs(new Date().getTime())
        }
        return r;
    }

    async Logout() {
        delete cache.login

        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.logout), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        for (let cb of this.callbacks.logout) cb()
        return r;
    }

    async ConfirmRecoveryCode(recovery_code) {
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.confirmRecoveryCode, {recovery_code}), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        return r;
    }

    async ResetPassword(recovery_code, password) {
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.resetPassword, {recovery_code, password}), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        return r;
    }

    async ForgotPassword(identifier) {
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.forgotPassword, {identifier}), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        return r;
    }
}
