<template>
  <div class="update-message">
    <div class="icon-holder" v-if="!messengerOpen" >
      <img :src="MessagesAvatar" @click="toggleMessenger" class="toggle-icon"/>
    </div>
    <div v-if="messengerOpen" class="messenger-body">
      <div class="messenger-header" @click="toggleMessenger">
        {{t("Yogev Malka")}}
      </div>
      <div class="message-wrapper" v-if="!formOpen">
        <img :src="MessagesAvatar" class="message-avatar" />
        <div :class="'message-inner' + (playingAudio?' playing':'')">
          <img :src="PlayButton" class="play-button" @click="onPlay" />
          <PhoneAudioBars :pos="audioPos" />
          <audio :src="audioSrc" v-if="audioSrc" ref="audioEl" />
        </div>
      </div>
      <div class="feedback-wrapper" v-if="!formOpen">
        <button class="active" @click="toggleForm">{{t("Write feedback")}}</button>
        <button @click="dismissFeedbackForm">{{t("Leave me alone")}}</button>
      </div>
      <div class="feedback-form" v-if="formOpen">
        <InputWrapper :validate="false" :label="t('Feedback')" type="textarea" v-model="feedbackMessage" :placeholder="t('Tell us how it\'s going')" />
        <div class="feedback-wrapper">
          <button :class="feedbackMessage?'active':null" @click="submitFeedbackForm">{{t("Send")}}</button>
          <button @click="toggleForm">{{t("Cancel")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale"
import PlayButton from "@/assets/icons/play-button-dark.svg";
import MessagesAvatar from "@/assets/images/messages-avatar.png";
import PhoneAudioBars from "@/components/ui/widgets/PhoneAudioBars.vue";
import InputWrapper from "@/components/ui/widgets/InputWrapper.vue";
export default {
  name: "UpdateMessage",
  components: {InputWrapper, PhoneAudioBars},
  props : {
    date: String,
    submitForm: Function,
  },
  data() {
    return {
      t,
      MessagesAvatar, PlayButton,
      audioSrc: "https://powerninja.co.il/audio/update-message.mp3",
      feedbackMessage:"",
      audioPos: 0,
      messengerOpen: false,
      formOpen: false,
      playingAudio: false,
    }
  },

  mounted() {
    this.timeout = setInterval(this.updatePos, 100)
    this.updatePos()
  },
  beforeDestroy() {
    clearInterval(this.timeout)
  },

  methods: {
    toggleMessenger() {
      this.messengerOpen = !this.messengerOpen
    },
    toggleForm() {
      this.formOpen = !this.formOpen
    },
    async submitFeedbackForm() {
      if (this.feedbackMessage) this.submitForm(this.feedbackMessage)
    },
    async dismissFeedbackForm() {
      this.submitForm("")
    },
    onPlay() {
      const {audioEl} = this.$refs
      if (audioEl.paused) {
        audioEl.play()
        this.playingAudio = true
        this.updatePos()
      }
      else {
        audioEl.pause()
        this.playingAudio = false
        this.updatePos()
      }
    },
    updatePos() {
      const {audioEl} = this.$refs
      if (!this.messengerOpen || !audioEl) return
      const t = (audioEl.currentTime/audioEl.duration)
      if (!isNaN(t)) this.audioPos = t
      if (t >= 1) this.playingAudio = false
    }
  }
}
</script>
