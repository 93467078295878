<template>
  <div class="plan-wrapper">
    <div class="training-details" v-if="training">

      <Scroller v-if="isSuperSet" :items="superSetVideos" :pagination="true">
        <template v-slot:default="s">
        <Vimeo :path="s.item" :wrapped="true" />
        </template>
      </Scroller>
      <div class="superset-indicator" v-if="isSuperSet">
        <p>{{t("Superset: {0} videos", superSetVideos.length)}}</p>
      </div>

      <Vimeo :path="training.video" v-if="training.video && !isSuperSet" :wrapped="true" />
      <h3><span v-if="isWarmup">{{t("Warmup:")}}</span> {{training.training}}</h3>
      <p v-if="training.notes">{{training.notes}}</p>
      <div class="plan-separator"></div>
      <div v-for="(set, i) in sets" :key="i">
        <div class="set-details">
          <img :src="WorkoutIcon" />
          <div class="workout">
            <h4>{{set.label}}</h4>
            <p>{{training.ranges?trainingRange(training):set.reps}}</p>
          </div>
        </div>
        <div class="rest-details">
          <img :src="RestIcon" />
          <div>
            <p>{{set.rest}}</p>
          </div>
        </div>
      </div>
      <button @click="nextTraining" class="active">{{t(hasNextTraining?(hasNextWarmup?"Next Warmup":"Next Training"):"Back to daily practice")}}</button>
    </div>
  </div>

</template>
<script>
import t from '@/lib/Locale'
import Vimeo from "@/components/ui/widgets/Vimeo";
import WorkoutIcon from "@/assets/icons/plan/training-workout.svg"
import RestIcon from "@/assets/icons/plan/training-rest.svg"
import {getLoginDetails, setCurrentTraining} from "@/lib/PlanUtils";
import Scroller from "@/components/ui/widgets/Scroller";
import {getLastTestResults} from "@/lib/DiaryUtils";

export default {
  name: "TrainingView",
  components: {Scroller, Vimeo},
  data() {
    return {
      t,
      WorkoutIcon, RestIcon,
      training : {},
      meta: {}
    }
  },
  async mounted() {
    const login = await getLoginDetails('/plan/login')
    this.subscriber = login.subscriber
    this.meta = login.meta
    let training = JSON.parse(localStorage.getItem('current-training'))
    if (training) this.training = training;
  },
  computed : {
    isSuperSet() {
      if (!this.training || !this.training.video) return false
      let videos =  this.training.video.toString().split(/,/g)
      return videos.length > 1
    },
    superSetVideos() {
      return this.training.video.split(/,/g)
    },
    isWarmup() {
      return this.training && typeof this.training.warmupIndex !== "undefined"
    },
    hasNextWarmup() {
      return this.isWarmup && this.training.warmupIndex < 2
    },

    hasNextTraining() {
      if (this.isWarmup) return true

      const trainings = JSON.parse(localStorage.getItem('day-trainings'))
      for (let i = 0; i < trainings.length; i++) {
        if (trainings[i].order === this.training.order) {
          return (i < trainings.length - 1)
        }
      }
      return false
    },
    sets() {
      let sets = []
      for (let i = 0; i < this.training.sets; i++) {
        sets.push({
          label: t("Set #{0}", i+1),
          reps: !isNaN(this.training.reps*1)?t("{0} reps", this.training.reps):this.training.reps,
          rest: this.training.rest
        })
      }
      return sets
    }
  },
  methods : {
    trainingRange(t) {
      const testResults = getLastTestResults(this.meta)
      let r;
      if (t.ranges) {
        r = testResults[t.ranges]
        if (r) {
          let range = t.reps.match(/([0-9]+)(%)?-([0-9]+)%/g)
          if (range) {
            range = range[0]
            range = range.split("-").map(it => it[it.length-1]==='%'?it.substr(0,it.length-1)*1: it*1)
          }
          else {
            range = t.reps.match(/([0-9]+)%/g)[0].split("-")
            range = range.map(it => it[it.length-1]==='%'?it.substr(0,it.length-1)*1: it*1)
          }
          if (range.length > 1) {
            return t.reps.replace(/([0-9]+)%/g, Math.ceil(range[1]*r/100)).replace(/([0-9]+)-/g,(Math.ceil(range[0]*r/100))+'-')
          }
          else {
            return t.reps.replace(/([0-9]+)%/, Math.ceil(range[0]*r/100))
          }
        }
      }
      else return t.training
    },
    async nextTraining() {
      const trainings = JSON.parse(localStorage.getItem('day-trainings'))

      if (!this.hasNextTraining) return this.$router.push('/plan/daily/'+localStorage.getItem('current-date'))
      if (typeof this.training.warmupIndex !== "undefined") {
        if (this.training.warmupIndex < 2) {
          const warmups = JSON.parse(localStorage.getItem('day-warmups'))
          await setCurrentTraining(warmups[this.training.warmupIndex + 1])
          this.training = { ...warmups[this.training.warmupIndex + 1], warmupIndex: this.training.warmupIndex+1 }
          return true
        }
        else {
          this.training = trainings[0];
          return await setCurrentTraining(trainings[0])
        }
      }

      let training;
      for (let i = 0; i < trainings.length; i++) {
        if (trainings[i].order === this.training.order) {
          if (i < trainings.length - 1) {
            training = trainings[i+1]
          }
          else {
            training = null
          }
          break
        }
      }
      if (training) {
        await setCurrentTraining(training)
        this.training = training
      }
    }
  }
}
</script>
