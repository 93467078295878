<template>
  <div class="plan-wrapper">
    <h2>{{planWeekLabel}}</h2>
    <WeekNavigator :date="weekDate" :next="nextWeek" :prev="prevWeek" />

    <div class="weekly-plan">
      <div :class="dayClass(i)" v-for="(day,i) in days" :key="i">
        <div :class="'day-dates' + (isToday(i)?' today':'')">
          <p>{{formattedDate(i)}}</p>
          <h3>{{t(day)}}</h3>
        </div>
        <div class="day-inner loading" v-if="!inited">{{t("...")}}</div>
        <div class="day-inner" @click="() => onDayClick(i)" v-if="inited">
          <div class="day-details" v-if="hasTrainings(i) && beforePlanEnd(i) && !isTestDay(i)">
            <div class="day-description">
            <img :src="PowerTrainingIcon" class="training-icon" />
              <p>{{t("Power Training")}}</p>
            </div>
            <div class="trainings">
              <div class="info">
                <img :src="TrainingsIcon" />
                <p>{{dayTrainings(i)}}</p>
              </div>
              <div class="info">
                <img :src="DifficultyIcon" />
                <p>{{dayDifficulty(i)}}</p>
              </div>
            </div>
          </div>
          <div class="training-completed" v-if="trainingCompleted(i)">
            <img :src="CheckIcon" />{{t("Completed")}}
          </div>

          <div v-if="isTestDay(i)" class="note">
            <img :src="TestIcon" /><b>{{t("Skill test Day")}}</b>
          </div>
          <div class="rest-day" v-if="withinPlan(i) && restDay(i) && !isTestDay(i)">
            <img v-for="(icon, ii) in restDayIcons(i)" :key="ii" :src="icon" />
            <p>{{restDayLabel(i)}}</p>
          </div>
          <div v-if="!withinPlan(i)" class="note">
            <span v-if="!isTestDay(i)">{{t(!afterPlanStart(i)?"Plan didn't start":"Next period")}}</span>
          </div>
        </div>
      </div>
    </div>

    <DayActivityModal v-if="customizeDay" :title="customizeDayLabel" :dismiss="dismissCustomize" :activity="customDailyActivity" :on-activity="setRestDayActivity" :rest-days="weeklyRestDays" />

    <BottomNavbar />

  </div>
</template>

<script>
import t from '@/lib/Locale'
import TrainingsIcon from '@/assets/icons/plan/pullups.svg';
import DifficultyIcon from '@/assets/icons/plan/diary-difficulty.svg';
import ClockIcon from '@/assets/icons/plan/diary-clock.svg';
import RestingIcon from '@/assets/icons/plan/training-rest.svg';
import NinjaIcon from '@/assets/icons/plan/training-ninja.svg';
import OtherPracticeIcon from '@/assets/icons/plan/training-other.svg';
import CheckIcon from '@/assets/icons/check-circle.svg';
import TestIcon from '@/assets/icons/plan/test.svg';
import PowerTrainingIcon from '@/assets/icons/plan/badge-power.svg';

import WeekNavigator from "@/components/plan/WeekNavigator";
import BottomNavbar from "@/components/plan/BottomNavbar";
import {
  dateToString,
  daysInPlan,
  getCurrentTrainingDays,
  getSunday,
  howManyActivitiesOfType,
  loadDiaryData, nextTestDate,
  saveDiaryData
} from "@/lib/DiaryUtils";
import DayActivityModal from "@/components/plan/DayActivityModal";
import {
  getLoginDetails,
  getPlanCurrentPeriodDate, getPlanStartDate,
  getSubscriberSchedule
} from "@/lib/PlanUtils";

let days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]

export default {
  name: "WeeklyPlan",
  components: {DayActivityModal, BottomNavbar, WeekNavigator, },

  data() {
    return {
      TrainingsIcon, DifficultyIcon, ClockIcon, PowerTrainingIcon,
      RestingIcon, NinjaIcon, OtherPracticeIcon, CheckIcon, TestIcon,
      days,
      planStartDate: null,
      currentPeriodDate: null,
      schedule : [],
      customSchedule : [],
      completedPractices : [],
      highlight: null,
      weekDate : null,
      nextTestDate : null,
      customizeDay: null,
      subscriber : null,
      meta : null,
      testResults: null,
      inited: false,
    }
  },

  async mounted() {
    if (localStorage.getItem('login-token')) {
      const login = await getLoginDetails('/plan/login')
      this.subscriber = login.subscriber
      this.meta = login.meta
      this.planStartDate = getPlanStartDate(login.subscriber, login.meta)
      this.currentPeriodDate = getPlanCurrentPeriodDate(login.subscriber, login.meta)

      this.nextTestDate = nextTestDate(login.meta, this.currentPeriodDate, getCurrentTrainingDays(this.meta))
      if (login.meta['test-results']) this.testResults = login.meta['test-results']
      this.weekDate = getSunday()

      this.schedule = await getSubscriberSchedule(login.subscriber, login.meta)

      const [completedPractices, customRestDays] = loadDiaryData(login.meta)
      this.customSchedule = customRestDays
      this.completedPractices = completedPractices

      this.inited = true

      if (this.$route.query && this.$route.query.hl) {
        this.highlightDate(this.$route.query.hl)
      }

    }
  },

  computed : {

    planWeek() {
      const weekDiff = ((this.weekDate - this.currentPeriodDate)/1000/60/60/24/7)
      return (Math.round(weekDiff)+1)
    },
    weeklyRestDays() {
      let to = new Date(this.weekDate)
      to.setDate(to.getDate()+7)
      return howManyActivitiesOfType(this.customSchedule, this.schedule, 'rest', dateToString(this.weekDate), dateToString(to))
    },
    planWeekLabel() {
      const week = this.planWeek
      if (week < 1) {
        if (this.weekDate < this.planStartDate) return t("Before plan start")
        else return t("Previous training period")
      }
      if (week > 6) return t("Next period")
      return t('Week {0} of {1}',Math.min(6,week),6)
    },
    customDailyActivity() {
      const custom = this.customSchedule.find(it => it.date === this.customizeDay)
      let activity = custom?custom.activity || ['rest'] : ['rest']
      if (typeof activity === "string") activity = [activity]
      return activity
    },
    customizeDayLabel() {
      if (!this.customizeDay) return ''
      const d = new Date(this.customizeDay)
      return t(days[d.getDay()]) + ", "+d.getDate()+'/'+(d.getMonth()+1)

    }
  },
  methods : {
    t,

    highlightDate(date) {
      if (!date) return;
      date = new Date(date)
      this.highlight = dateToString(date);
      let dateSunday = new Date(date)
      dateSunday.setDate(dateSunday.getDate()-dateSunday.getDay())
      this.weekDate = dateSunday
    },

    onDayClick(day) {
      if (this.withinPeriod(day) && this.hasTrainings(day) && !this.isTestDay(day)) {
        const d = this.dayDate(day)
        this.$router.push('/plan/daily/'+d)
      }
      else if (this.withinPeriod(day) && !this.isTestDay(day)) {
        this.customizeDay = this.dayDate(day)
      }
    },

    async setRestDayActivity(activity, customizeAhead = false) {
      let schedule = [...this.customSchedule]
      const index = schedule.findIndex(d => d.date === this.customizeDay)
      if (index > -1) schedule.splice(index, 1)
      schedule.push({date: this.customizeDay, activity})

      if (customizeAhead) {
        let endDate = new Date(this.planStartDate)
        endDate.setDate(endDate.getDate() + 42)

        let date = new Date(this.customizeDay), index2, dd
        date.setDate(date.getDate()+7)
        while (dateToString(date) <= dateToString(endDate)) {
          dd = dateToString(date)
          index2 = schedule.findIndex(d => d.date === dd)
          while (index2 > -1) {
            schedule.splice(index2, 1)
            index2 = schedule.findIndex(d => d.date === dd)
          }
          schedule.push({date: dd, activity})
          date.setDate(date.getDate()+7)
        }
      }
      this.customSchedule = schedule

      await saveDiaryData(this.completedPractices, this.customSchedule)

      this.customizeDay = null
    },

    dailyCustomSchedule(day) {
      const date = this.dayDate(day)
      return this.customSchedule.find(it => it.date === date)
    },
    dismissCustomize() { this.customizeDay = null },
    dayDate(day) {
      let date = new Date(this.weekDate);
      if (date) date.setDate(date.getDate()+day)
      return dateToString(date)
    },
    todayDate() {
      const date = new Date()
      return dateToString(date)
    },
    formattedDate(day) {
      const date = new Date(this.dayDate(day))
      return date.getDate()+'/'+(date.getMonth()+1)
    },

    prevWeek() {
      const date = new Date(this.weekDate)
      date.setDate(date.getDate()-7)
      this.weekDate = date
    },
    nextWeek() {
      const date = new Date(this.weekDate)
      date.setDate(date.getDate()+7)
      this.weekDate = date
    },

    restDayIcons(day) {
      const custom = this.dailyCustomSchedule(day)
      if (!custom) return [RestingIcon]
      let activity = (typeof custom.activity === "string") ? [custom.activity] : [...custom.activity]
      let icons = []
      for (let act of activity)
      switch (act) {
        case 'rest': icons.push(RestingIcon);break
        case 'ninja': icons.push(NinjaIcon);break
        case 'other': icons.push(OtherPracticeIcon);break
      }
      return icons
    },
    restDayLabel(day) {
      let custom = this.dailyCustomSchedule(day)
      if (!custom) return t("Rest Day")
      if (custom.activity instanceof Array) {
        if (custom.activity.length === 1) custom.activity = custom.activity[0]
        else return t("{0} activities", custom.activity.length)
      }
      switch (custom.activity) {
        case 'rest': return t("Rest Day")
        case 'ninja': return t("Ninja Practice")
        case 'other': return t("Another Practice")
      }
    },

    dayClass(day) {
      let c = 'weekly-plan-day';
      if (!this.weekDate) return c
      if (this.beforePlanEnd(day) && this.hasTrainings(day)) c += this.trainingCompleted(day)?' has-training training-completed':' has-training'       // has training
      if (!this.withinPeriod(day)) c += ' past-period'       // in a past period
      if (this.dayDate(day) === dateToString(this.nextTestDate)) c += ' test'       // test date
      if (this.todayDate() === this.dayDate(day)) c += ' today'        // today
      else if (this.todayDate() > this.dayDate(day)) c += ' past'      // past
      else if (this.todayDate() < this.dayDate(day)) c += ' future'    // future

      if (this.dayDate(day) === this.highlight) {
        c += ' highlight'       // test date
        if (this.hasTrainings(day)) {
          setTimeout(() => {
            this.$router.push('/plan/daily/'+this.dayDate(day))
          }, 1550)
        }
      }
      return c
    },

    dayDifficulty(day) {
      let str = ""
      for (let practice of this.schedule) {
        if (dateToString(practice.date) === this.dayDate(day)) {
          return t(practice.difficulty);
        }
      }
      return str
    },

    dayTrainings(day) {
      let str = ""
      for (let practice of this.schedule) {
        if (dateToString(practice.date) === this.dayDate(day)) {
          return t("{0} trainings", practice.trainings.length);
        }
      }
      return str
    },

    isToday(day) {
      return dateToString(new Date()) === this.dayDate(day)
    },

    isTestDay(day) {
      let dayDate = this.dayDate(day)
      if (this.testResults) {
        let dates = this.testResults.map(it => it.date)
        if (dates.indexOf(dayDate) > -1) return true
      }
      return dateToString(this.nextTestDate) === dayDate
    },

    afterPlanStart(day) {
      return dateToString(this.planStartDate) <= this.dayDate(day)
    },

    afterPeriodStart(day) {
      return dateToString(this.currentPeriodDate) <= this.dayDate(day)
    },

    beforePlanEnd(day) {
      if (!this.currentPeriodDate) return false
      let date = new Date(this.currentPeriodDate), days = daysInPlan(this.currentPeriodDate, getCurrentTrainingDays(this.meta))
      date.setDate(date.getDate() + days)

      return dateToString(date) >= this.dayDate(day)
    },

    withinPlan(day) {
      return this.afterPlanStart(day) && this.beforePlanEnd(day)
    },

    withinPeriod(day) {
      return this.afterPeriodStart(day) && this.beforePlanEnd(day)
    },

    hasTrainings(day) {
      for (let practice of this.schedule) if (dateToString(practice.date) === this.dayDate(day)) return true
    },

    trainingCompleted(day) {
      day = this.dayDate(day)
      return this.completedPractices instanceof Array && !!this.completedPractices.find(it => it.date === day)
    },

    restDay(day) {
      return !this.hasTrainings(day)
    }
  }
}
</script>
