<template>
  <div class="week-navigator">
    <img :src="ChevronRight" @click="clickPrev" />
    <span>{{currentDate}}</span>
    <img :src="ChevronLeft" @click="clickNext" />
  </div>
</template>

<script>

import ChevronLeft from "@/assets/icons/chevron-left.svg"
import ChevronRight from "@/assets/icons/chevron-right.svg"

export default {
  props : ['date','prev','next'],
  name: "WeekNavigator",
  data() {
    return {
      ChevronRight,
      ChevronLeft
    }
  },
  computed : {
    currentDate() {
      let start = new Date(this.date), end = new Date(this.date);
      end.setDate(end.getDate()+6)

      return start.getDate()+'/'+(start.getMonth()+1)+' - '+end.getDate()+'/'+(end.getMonth()+1) + ' ' + end.getFullYear()
    }
  },
  methods : {
    clickNext() {
      if (this.next) this.next()
    },
    clickPrev() {
      if (this.prev) this.prev()
    }
  }
}
</script>
