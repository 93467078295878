<template>
  <div class="plan-wrapper">
    <div class="plan-centered-info">
      <img :src="CalendarIcon" class="big-icon" />

      <h2>{{t("Start Plan Next Week?")}}</h2>
      <p>{{t("start-plan-next-week-text")}}</p>
    </div>

    <div class="plan-panel">
      <h3>{{t("So, what do you prefer?")}}</h3>
      <div class="btn-group">
        <button class="active" @click="onImmediately">{{t("Start Plan Immediately")}}</button>
        <button @click="onNextWeek">{{t("Start Plan Next Week")}}</button>
      </div>
    </div>

  </div>
</template>
<script>
import t from "@/lib/Locale"
import CalendarIcon from "@/assets/icons/calendar.svg"
import {getLoginDetails, setPlanStartDate} from "@/lib/PlanUtils";

export default {
  name: "StartPlanNextWeek",
  data() {
    return {
      t,
      CalendarIcon,
      subscriber: null,
      amount: ""
    }
  },
  async mounted() {
    const login = await getLoginDetails('/plan/login')
    this.subscriber = login.subscriber;
  },
  methods : {
    onImmediately() {
      const date = new Date();
      setPlanStartDate(date, this.subscriber)
      this.$router.push("/plan/home")
    },
    onNextWeek() {
      const date = new Date();
      date.setDate(date.getDate()+7-date.getDay())
      setPlanStartDate(date, this.subscriber)
      this.$router.push("/plan/home")
    }
  }
}
</script>
