<template>
  <div class="plan-wrapper">
    <Flexy class="plan-centered-info">
      <img :src="CalendarIcon" class="big-icon" />
      <h3>{{t("One Last Thing")}}</h3>
      <p>{{t("choose-trainings-intro")}}</p>
    </Flexy>
    <WeekdaySelector :selected="trainingDays" :on-select="toggleTrainingDay" />
    <div :class="'toggle-error' + (toggleError?' active':'')">{{toggleError}}</div>
    <div class="btn active" @click="onSubmit">{{t("I Finished")}}</div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import WeekdaySelector from "@/components/plan/WeekdaySelector";
import Flexy from "@/components/ui/widgets/Flexy";
import CalendarIcon from "@/assets/icons/calendar.svg"
import {saveTrainingDays} from "@/lib/DiaryUtils";

export default {
  name: "ChooseTrainingDays",
  components: {Flexy, WeekdaySelector},

  data() {
    return {
      t,
      CalendarIcon,
      toggleError: null,
      toggleClear : null,
      trainingDays : [0,2,4]
    }
  },

  methods : {
    onSubmit() {
      saveTrainingDays(this.trainingDays)
      this.$router.push('/plan/home')
    },
    setToggleError(error) {
      this.toggleError = t(error)
      clearTimeout(this.toggleClear)
      this.toggleClear = setTimeout(() => {
        this.toggleError = null
      }, 2000)
    },
    toggleTrainingDay(day) {
      this.toggleError = null
      let toBe = [...this.trainingDays]
      if (toBe.indexOf(day) > -1) {
        // ...
        toBe.splice(toBe.indexOf(day), 1)
      }
      else {
        toBe.push(day)
      }
      toBe = toBe.sort()
      // scan in a row
      let inARow = 0
      if (toBe.length > 3) {
        this.setToggleError("3 power trainings is the maximum amount in a week")
        return
      }
      for (let i = 0; i < 10; i++) {
        if (toBe.indexOf(i%7) > -1) inARow++
        else inARow = 0
        if (inARow > 2) {
          this.setToggleError("No more than 2 power trainings in a row are allowed")
          return
        }
      }
      this.trainingDays = toBe;
    }
  }

}
</script>
