<template>
  <div class="plan-wrapper">
    <div class="success-bar" v-if="success"><p>{{success}}</p></div>
    <div class="plan-panel raw">
      <InputWrapper :label="t('First Name')" type="name" v-model="first_name" :placeholder="t('First Name')" />
      <InputWrapper :label="t('Last Name')" type="name" v-model="last_name" :placeholder="t('Last Name')" />
      <InputWrapper :label="t('E-mail *')" type="email" v-model="email" :placeholder="t('E-mail Address')" />
      <div class="btn-group">
        <button class="btn active" @click="updateSubscriberDetails">{{t("Save Changes")}}</button>
        <button class="btn secondary" @click="resetSubscriberDetails">{{t("Reset")}}</button>
      </div>
    </div>

    <div class="plan-panel">
      <InputWrapper :label="t('New Password')" type="password" v-model="newPassword" :placeholder="t('New Password')" />
      <InputWrapper :label="t('Confirm New Password')" type="password2" v-model="newPassword2" :pass1="newPassword" :placeholder="t('Confirm New Password')" />
      <Flexy>
        <button :class="'btn'+(canSavePassword?' active':' disabled')" @click="updatePassword">{{t("Update Password")}}</button>
      </Flexy>
    </div>

  </div>
</template>

<script>
import t from '@/lib/Locale';
import Fito from '@/lib/fito';
import Flexy from "@/components/ui/widgets/Flexy";
import InputWrapper from "@/components/ui/widgets/InputWrapper";


export default {
  name: "EditProfile",
  components: { InputWrapper, Flexy},
  data() {
    return {
      newPassword: '',
      newPassword2 : '',

      first_name: '',
      last_name: '',
      email: '',

      login : {},
      expired: false,
      success: '',

    }
  },
  async mounted() {
    const login = await Fito().Login.CheckLogin();
    if (!login) await this.$router.push('/plan/login');


    this.login = login
    this.expired = login.status === 'expired'
    this.first_name = login.subscriber.first_name
    this.last_name = login.subscriber.last_name
    this.email = login.subscriber.email

  },

  methods: {
    t,

    async updateSubscriberDetails() {
      await Fito().Plan.UpdateAccountDetails(this.first_name, this.last_name, this.email)
      this.showSuccess(t("Successfully updated subscriber details"))
    },
    resetSubscriberDetails() {
      this.first_name = this.login.subscriber.first_name
      this.last_name = this.login.subscriber.last_name
      this.email = this.login.subscriber.email
    },

    async updatePassword() {
      if (this.canSavePassword) {
        await Fito().Plan.ChangePassword(this.newPassword, this.newPassword2)
        this.newPassword = this.newPassword2 = '';
        this.showSuccess(t("Successfully reset password"))
      }
    },

    showSuccess(message) {
      this.success = message;
      setTimeout(() => this.success = '', 5000)
    },

  },

  computed : {
    canSavePassword() { return this.newPassword === this.newPassword2 && this.newPassword.length >= 8 },
  }
}
</script>
