<template>
  <div class="quiz-content">
    <div class="quiz-main">
      <div class="question-icon" v-if="icon"><img :src="icon" /></div>
      <h3>{{t(question)}}</h3>
      <div class="info" v-if="info">
        <p>{{t(info)}}</p>
      </div>
      <div :class="'quiz-answers'+(type ? ' '+type:'')">
        <div class="answer-wrapper" v-for="(option, i) in answers" :key="option.label" @click="doAnswer(option, i)">
          <a class="answer" href="javascript:void(0)" v-if="testOption(option)" :tabindex="i+1">
            {{t(option.label)}}
            <div v-if="inline && inlineIndex === i" class="inline-question">
              <h4>{{t(inline.question)}}</h4>
              <div class="btn-group">
                <div v-for="(answer, ii) in inline.answers" :key="ii" class="btn" @click="doAnswer(answer)">
                  {{t(answer.label)}}
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div v-if="type === 'slider'">
        <input type="range" :max="max" :value="sliderValue" @input="onSliderChange" @change="onSliderChange">
        <div class="slider-value">{{sliderValue}}</div>
        <div class="btn" @click="submitSlider">{{t("Next")}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale";

export default {
  name: "QuizQuestion",
  props: ["question","answers","onAnswer", "info", "list", "type", "results", "goto","min","max","icon", "fullIcon"],
  data() {
    return {
      sliderValue: 1,
      inlineIndex: null,
      inline: null
    }
  },
  methods: {
    t,
    doAnswer(option, index) {
      if (option.inline) {
        if (this.inline) {
          this.onAnswer(option.value)
        }
        else {
          this.inlineIndex = index
          this.inline = option.inline
        }
      }
      else {
        this.inlineIndex = null
        this.inline = null
        this.onAnswer(option.value)
      }
    },
    testOption(option) {
      if (!option.test) return true;
      return (option.test(this.results))
    },
    onSliderChange(e) {
      this.sliderValue = e.target.value
    },
    submitSlider() {
      this.onAnswer(this.sliderValue)
    }
  }
}
</script>

