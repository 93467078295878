<template>
  <div class="plan-wrapper" v-if="subscriber">
    <div class="success-bar" v-if="success"><p>{{success}}</p></div>

    <h2>{{greeting}}</h2>

    <LiveNotification v-if="showLive" starts="2023-02-26 19:30:00" ends="2023-02-27 22:00:00" path="2951718" />

    <div class="goal-wrapper">
      <h4>{{t('Week {0} of {1} of the plan',currentPlanWeek,6)}}</h4>
      <p>{{weeklyGoal}}</p>
    </div>

    <div class="stats-row">
      <PlanStat label="Completed practices" :value="completedPractices.length" :icon="CompletedTrainingsIcon" />
      <PlanStat label="Completed trainings" :value="completedTrainings" :icon="ExercisesIcon" />
    </div>

    <NinjaPointsBar :points="completedPractices.length * 30" />
    <PlanStatsRow label="Last Test Result" :icon="TestResultsIcon" :stats="testResults" :all-results="allTestResults" />

    <NextPracticeBar :schedule="schedule" :completed-practices="completedPractices" />

    <UpdateMessage v-if="showUpdateMessage" :submit-form="submitFeedback" />

    <BottomNavbar />

  </div>
</template>

<script>
import t from '@/lib/Locale'
import Logo from '@/assets/images/gorilla-logo.svg';
import leftArrowIcon from '@/assets/icons/arrow-left.svg';
import CompletedTrainingsIcon from '@/assets/icons/plan/badge-completed-trainings.svg';
import ExercisesIcon from '@/assets/icons/plan/badge-exercises.svg';
import ScoreIcon from '@/assets/icons/plan/badge-score.svg';
import DifficultyIcon from '@/assets/icons/plan/diary-difficulty.svg';
import TrainingsIcon from '@/assets/icons/plan/diary-trainings.svg';

import BottomNavbar from "@/components/plan/BottomNavbar";
import NinjaPointsBar from "@/components/plan/NinjaPointsBar";
import PlanStat from "@/components/plan/PlanStat";
import {
  dateToString,
  getCurrentTrainingDays,
  getLastTestResults,
  getSunday,
  loadDiaryData,
  nextTestDate
} from "@/lib/DiaryUtils";
import {
  getLoginDetails,
  getPlanCurrentPeriodDate, getPlanStartDate,
  getSubscriberSchedule,
  planWeek
} from "@/lib/PlanUtils";
import TestResultsIcon from '@/assets/icons/plan/badge-power.svg';
import PlanStatsRow from "@/components/plan/PlanStatsRow";
import LiveNotification from "@/components/ui/widgets/LiveNotification.vue";
import UpdateMessage from "@/components/ui/widgets/UpdateMessage.vue";
import Fito from "@/lib/fito";
import NextPracticeBar from "@/components/ui/widgets/NextPracticeBar.vue";

let testResultLabels = {
  "pulls":"Bar Pulls", "hold_both":"Holding with both hands", "hold_right":"Holding with right hand", "hold_left":"Holding with left hand"
}


export default {
  name: "PlanHome",
  components: {
    NextPracticeBar,
    UpdateMessage, LiveNotification, PlanStatsRow, PlanStat, NinjaPointsBar, BottomNavbar},
  data() {
    return {
      Logo,
      leftArrowIcon,
      CompletedTrainingsIcon, TestResultsIcon,
      ExercisesIcon,
      ScoreIcon,
      DifficultyIcon,
      TrainingsIcon,

      weekDate: null,
      planStartDate: null,
      currentPeriodDate: null,
      schedule: [],
      customSchedule : [],
      completedPractices : [],
      subscriber : null,
      lastTestResults: null,
      subscriberPlan: null,
      meta: null,
      sentFeedback: false,
      success: null,
    }
  },
  async mounted() {
    if (localStorage.getItem('login-token')) {
      const login = await getLoginDetails('/plan/login', null, true)

      const lastTestResults = getLastTestResults(login.meta);
      this.meta = login.meta
      if (!lastTestResults) return this.$router.push('/plan/test')
      this.lastTestResults = lastTestResults

      this.subscriber = login.subscriber

      if (login.plan && login.plan.name) this.subscriberPlan = login.plan.name

      this.planStartDate = getPlanStartDate(login.subscriber, login.meta)
      this.currentPeriodDate = getPlanCurrentPeriodDate(login.subscriber, login.meta)
      this.schedule = await getSubscriberSchedule(login.subscriber, login.meta)

      this.weekDate = getSunday()

      const [completedPractices, customRestDays] = loadDiaryData(login.meta)
      this.customSchedule = customRestDays
      this.completedPractices = completedPractices

      if (this.pendingTest) {
        this.$router.push('/plan/test')
      }

    }
  },

  computed : {

    showLive() {
      return (this.subscriberPlan && this.subscriberPlan.indexOf("פרו") > -1);
    },

    showUpdateMessage() {
      let tests = [...this.allTestResults]
      if (tests.length < 2 || this.sentFeedback || !this.dateForFeedback) return false
      return true
    },

    dateForFeedback() {
      let tests = [...this.allTestResults], feedbacks = this.meta['test-feedback'] ? [...this.meta['test-feedback']] : []
      tests = tests.sort((a, b) => a.date < b.date ? -1 : 1)
      tests.shift()
      for (let test of tests) {
        if (feedbacks.find(f => f.date === test.date)) continue
        return test.date
      }
      return null
    },

    greeting() {
      if (!this.subscriber) return ''
      const time = new Date().getHours()
      let greeting = 'good morning'
      if (time < 5 || time > 20) greeting = 'good night'
      else if (time > 16) greeting = 'good evening'
      else if (time > 14) greeting = 'good afternoon'
      else if (time > 12) greeting = 'good noon'

      return t("{0}, {1}",t(greeting), this.subscriber.first_name)
    },

    pendingTest() {
      const testDate = nextTestDate(this.meta, this.currentPeriodDate, getCurrentTrainingDays(this.meta))
      if (dateToString(testDate) <= dateToString(new Date())) {
        return true
      }

      return false
    },

    currentPlanWeek() {
      return this.weekDate && this.currentPeriodDate ? planWeek(this.weekDate, this.currentPeriodDate):1
    },

    completedTrainings() {
      let t = 0
      for (let p of this.completedPractices) t += p.trainings
      return t
    },

    weeklyGoal() {
      return t('weekly-goal-'+this.currentPlanWeek)
    },

    testResults() {
      const results = this.lastTestResults
      let r = []
      for (let i in results) if (testResultLabels[i]) r.push({label: testResultLabels[i], value: results[i]})
      return r
    },
    allTestResults() {
      return this.meta['test-results'] || []
    }
  },

  methods : {
    t,

    async submitFeedback(feedback = "") {
      this.sentFeedback = true
      await Fito().RunAction('submit-feedback', {date: this.dateForFeedback, feedback})
      if (feedback) {
        this.success = t("Feedback Sent Successfully")
        setTimeout(() => this.success = "", 3000)
        await Fito().Plan.Log('submitted-test-feedback', this.dateForFeedback )
      }
      else {
        await Fito().Plan.Log('dismissed-test-feedback', this.dateForFeedback )
      }
    }
  }
}
</script>
