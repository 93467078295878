<template>
  <div class="panel choose-plan">
    <span class="back-link raw" v-if="onCancel">
      <Flexy><img :src="BackIcon" @click="onCancel" /> <p>{{t('Choose the right plan for you')}}</p></Flexy>
    </span>
    <div class="boxes light plans">
      <Box v-for="(plan, ii) in myPlans" :key="ii" :title="plan.name" @click="() => onSelect(plan)" :class="plan.discount?'discount':''">
        <div class="prices">
          <div class="crossed-price" v-if="plan.full_price">{{t("{0} nis", plan.full_price)}}</div>
          <div class="price">{{t("{0} nis", plan.price)}}<small>{{t(" / month")}}</small></div>
        </div>
        <div class="description" v-if="plan.description">{{plan.description}}</div>
      </Box>
    </div>
    <div>
      <div class="centered small">
        {{t("You can always cancel your plan in your user profile")}}
      </div>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale";
import BackIcon from '@/assets/icons/arrow-back.svg';
import Flexy from "@/components/ui/widgets/Flexy";
import Box from "@/components/ui/widgets/Box";


export default {
name: "ChoosePlanForm",
  components: {Box, Flexy},
  props : {
    plans : {type: Array},
    onSelect : {type: Function},
    onCancel : {type: Function},
  },

  data : function() {
    return {
      BackIcon,

      remember: false,
    }
  },

  mounted() {
  },

  computed : {
    myPlans() {
      return this.plans
    }
  },

  methods : {
    t,

  },


}
</script>
