export const doRequest = async (uri, method = "GET", body = {}, moreHeaders = {}) => {

    let headers = new Headers();


    const params = {
        method, headers,
        credentials: 'include'
    };
    for (let h in moreHeaders) {
        headers.append(h, moreHeaders[h])
    }
    if (method.toLowerCase() === "post" || method.toLowerCase() === "put") {
        headers.append('Content-Type','application/json');
        params.body = JSON.stringify(body);
    }

    const response = await fetch(uri, params);
    if (response.status === 404 || response.status === 400) {
        const resJson = await response.json();
        throw(resJson);
    }
    else if (response.status === 200) {
        const contentType = response.headers.get('content-type')
        let ret;
        if (contentType.indexOf('pdf') > -1) {
            ret = await response.blob()
            console.log(ret)
            window.location.href = URL.createObjectURL(ret)
        }
        else {
            ret = await response.json();
            return ret;
        }
    }
};
