<template>
  <div class="plan-wrapper">
    <div class="success-bar" v-if="success"><p>{{success}}</p></div>
    <Flexy class="plan-centered-info">{{t("plan-gear-intro")}}</Flexy>
    <GearSelectionPanel :items="items" :selected="selectedGear" :on-select="onSelectGear" />
    <div :class="'toggle-error' + (toggleError?' active':'')">{{toggleError}}</div>
    <div class="plan-actions">
      <router-link class="btn" to="/plan/profile">{{t("Cancel")}}</router-link>
      <div class="btn active" @click="saveGear">{{t("Save")}}</div>
    </div>
  </div>

</template>

<script>
import t from '@/lib/Locale';
import Flexy from "@/components/ui/widgets/Flexy";
import GearSelectionPanel from "@/components/plan/GearSelectionPanel";
import {gearItems, getLoginDetails, setGear} from "@/lib/PlanUtils";
import Fito from "@/lib/fito";
let resettingGear = false


export default {
  name: "GearSelection",
  components: {GearSelectionPanel, Flexy, },

  data() {
    return {
      t, items: gearItems,
      selectedGear: [],
      success: null,
      toggleError: null,
      toggleClear : null,
      subscriber: null,
      gear : ['bar','candles']
    }
  },

  async mounted() {

    const login = await getLoginDetails('/plan/login')
    this.subscriber = login.subscriber

    let gear
    if (login.meta && login.meta.gear){
      gear = login.meta.gear
    }
    else {
      gear = localStorage.getItem('gear')
      gear = JSON.parse(gear)
    }

    if (!gear) {
      const answers = JSON.parse(localStorage.getItem('QuizAnswers'))
      if (answers) {
        gear = []
        for (let ans in answers) if (gearItems.find(it => it.id === ans && answers[ans])) gear.push(ans)
        if (!resettingGear) {
          resettingGear = true
          await Fito().RunAction('set-gear', { gear })
        }
      }
    }

    this.selectedGear = gear
  },

  methods : {
    async saveGear() {
      this.success = t("Saved gear settings");
      clearTimeout(this.successTimeout)

      await setGear(this.subscriber, this.selectedGear)
      this.successTimeout = setTimeout(() => {
        this.success = null
      }, 2000)
      await Fito().Login.CheckLogin(true)
    },

    onSelectGear(gear) {
      const items = [...this.selectedGear]
      if (items.indexOf(gear) > -1) {
        if (gear !== "bar" && gear !== "grip") // bar and grip are mandatory
          items.splice(items.indexOf(gear), 1)
      }
      else items.push(gear)
      this.selectedGear = items
    },

    setToggleError(error) {
      console.log(error)
    },

  }

}
</script>
