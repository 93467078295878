<template>
  <div class="post-signup-screen">
    <Modal v-if="showModal" :dismiss="dismissModal">
        <h2>{{t("Sign Up Complete")}}</h2>
        <button class="active" @click="dismissModal">{{t("Cool!")}}</button>
    </Modal>
    <h2>{{t('post-signup-title')}}</h2>
    <p class="intro">{{t('post-signup-content-1')}}</p>
    <p>{{t('post-signup-content-2')}}</p>
    <router-link class="btn active" to="/plan/test">{{t("Let's Start")}} <img :src="ArrowIcon" /></router-link>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import Fito from '@/lib/fito'
import Modal from "@/components/ui/widgets/Modal";
import ArrowIcon from "@/assets/icons/arrow-left.svg";

export default {
  name: "SignupComplete",
  components: {Modal},
  async mounted() {
    await this.completeSignup()
  },
  data() {
    return {
      ArrowIcon,
      showModal: true,
    }
  },

  methods : {
    t,
    dismissModal() {
      this.showModal = false
    },
    async completeSignup() {
      const signupToken = localStorage.getItem('SignupToken');
      const {res, token} = await Fito().Signup.CompleteSignup(signupToken)
      if (res === 'ok') {
        console.log("New token:", token)
        localStorage.setItem("login-token", token);
        localStorage.removeItem('SignupToken')
      }
      else {
        console.log("ERROR!!!", res)
      }
    }
  }
}
</script>
