<template>
  <component :is="tagName" class="box" v-bind="tagProps" @click="onClick">
    <Flexy class="box-inlay">
      <div v-if="thumb" class="box-thumb">
        <img :src="thumb" />
      </div>
      <div class="box-inner flex-1">
        <h3 v-if="title">{{title}}</h3>
        <slot></slot>
        <Flexy v-if="progressValue !== null">
          <div class="flex-1">
            <ProgressBar :done="progressValue" :small="true" />
          </div>
          <p class="light" v-if="typeof points !== 'undefined'">{{t('{0} points', points)}}</p>
        </Flexy>
        </div>
      <slot name="actions"></slot>
      <div v-if="actionIcon" class="box-action-icon">
        <img :src="actionIcon" />
      </div>
    </Flexy>
  </component>
</template>

<script>
import ProgressBar from "@/components/ui/widgets/ProgressBar";
import t from '@/lib/Locale';
import Flexy from "@/components/ui/widgets/Flexy";

export default {
  name: "Box",
  components: {Flexy, ProgressBar},
  props: ["title", "content", "progress", "points", "link", "thumb", "actionIcon"],
  computed : {
    progressValue() {
      return !isNaN(this.progress) ? this.progress : null
    },
    tagName() {
      return this.link ?
          (this.link.indexOf('://') > -1 ? 'a' : 'router-link')
          : 'div'
    },
    tagProps() {
      if (this.link) {
        if (this.link.indexOf('://') > -1) return { href : this.link }
        else return { to: this.link }
      }
      return {}
    }
  },
  methods : {
    t,
    onClick() {
      this.$emit("click")
    }
  }
}
</script>
