<template>
  <img :src="checked?CheckboxOnIcon:CheckboxIcon" @click="onSelect" />
</template>
<script>
import CheckboxIcon from "@/assets/icons/plan/checkbox-off.svg";
import CheckboxOnIcon from "@/assets/icons/plan/checkbox-on.svg";

export default {
    name: "Checkbox",
    props: {
      checked: Boolean,
      onSelect: Function,
    },
    data() {
      return {
        CheckboxIcon, CheckboxOnIcon,
    }
  }
}
</script>
