<template>
  <div class="plan-wrapper">
    <div class="success" v-if="success">{{success}}</div>

    <div class="plan-panel">
      <label>{{t("Receipts From Us")}}</label>
      <div class="boxes search-results">
        <Box v-for="(transaction) in login.transactions" :key="transaction.id">
          <Flexy :between="true">
            <span>{{parseDate(transaction.date)}}</span>
            <span>{{t("{0} nis", transaction.amount)}}</span>
            <button class="btn small" @click="() => getInvoice(transaction.id)">{{t("Get Invoice")}}</button>
          </Flexy>
        </Box>
        <Box v-for="(charge) in login.charges" :key="charge.id">
          <Flexy :between="true">
            <span>{{(charge.date)}}</span>
            <span>{{t("{0} nis", charge.amount)}}</span>
            <button class="btn small" @click="() => getInvoice(charge.id, true)">{{t("Get Invoice")}}</button>
          </Flexy>
        </Box>
      </div>
    </div>

    <div class="plan-panel" v-if="login.subscriber">
      <label>{{t("Sign up date")}}</label>
      <label>{{signupDate}}</label>
      <div v-if="!expired">
        <label>{{t(login.renew?"Renewal date":"Account expiry date")}} </label>
        <label>{{renewalDate}}</label>
      </div>
      <div v-if="expired">
        <router-link to="/plan/renew" class="btn">{{t("Renew Now")}}</router-link>
      </div>
      <div class="plan-actions" v-if="login.renew">
        <a class="btn active" @click="toggleCancelSubscription" v-if="!cancelSubscriptionDialog">{{t("Cancel Subscription")}}</a>
        <small v-if="cancelSubscriptionDialog">
          <h3>{{t("Are you sure?")}}</h3>
          <p>{{t("Canceling your subscription will allow you to use your account until the next billing period")}}</p>
          <div class="cancel-reason">
            <h3>{{ t("Please select the reason you want to cancel your subscription") }}</h3>
            <div><input type="radio" name="cancel-reason" v-model="cancelReason" value="It is too expensive for me" @change="onCancelReason" /><span>{{t("It is too expensive for me")}}</span></div>
            <div><input type="radio" name="cancel-reason" v-model="cancelReason" value="I was not satisfied with the content" @change="onCancelReason" /><span>{{t("I was not satisfied with the content")}}</span></div>
            <div><input type="radio" name="cancel-reason" v-model="cancelReason" value="I did not want a monthly subscription" @change="onCancelReason" /><span>{{t("I did not want a monthly subscription")}}</span></div>
            <div><input type="radio" name="cancel-reason" v-model="cancelReason" value="Other" @change="onCancelReason" /><span>{{t("Other")}}</span></div>
            <input v-if="cancelReason==='Other'" type="text" @change="onCancelOtherReason" />
          </div>
          <div class="btn-group">
            <a :class="'btn ' + (this.canCancel?'active':' disabled')" @click="doCancelSubscription">{{t('Yes')}}</a>
            <a class="btn secondary" @click="toggleCancelSubscription">{{t('No')}}</a>
          </div>
        </small>
      </div>
    </div>

  </div>
</template>

<script>
import t from '@/lib/Locale';
import Fito from '@/lib/fito';
import Flexy from "@/components/ui/widgets/Flexy";


export default {
  name: "AccountDetails",
  components: {Flexy},
  data() {
    return {
      login : {},
      expired: false,
      success: '',

      cancelled : false,
      cancelReason : '',
      cancelOtherReason : '',
      cancelSubscriptionDialog: false,
    }
  },
  async mounted() {
    const login = await Fito().Login.CheckLogin();
    if (!login) await this.$router.push('/plan/login');


    this.login = login
    this.expired = login.status === 'expired'
    this.first_name = login.subscriber.first_name
    this.last_name = login.subscriber.last_name
    this.email = login.subscriber.email

  },

  methods: {
    t,

    parseDate(d) {
      if (!d) return "-";
      d = new Date(d)
      return d.getDate()+'/'+(d.getMonth()+1)+'/'+d.getFullYear()
    },

    onCancelReason(e) {
      this.cancelReason   = e.target.value
    },

    onCancelOtherReason(e) {
      this.cancelOtherReason = e.target.value
    },

    async getInvoice(transactionId, isCharge) {
      if (isCharge) {
        await Fito().RunAction('cardcom-get-charge-invoice-pdf', { charge_id: transactionId })
      }
      else await Fito().RunAction('cardcom-get-invoice-pdf', { transaction_id: transactionId })
    },

    toggleCancelSubscription(e) {
      this.cancelSubscriptionDialog = !this.cancelSubscriptionDialog
      e.stopPropagation()
      return false
    },

    async doCancelSubscription() {
      if (!this.canCancel) return
      await Fito().Plan.CancelSubscription(this.cancelReason === 'Other' && this.cancelOtherReason?this.cancelOtherReason:this.cancelReason)
      this.login.renew = false
      this.cancelSubscriptionDialog = false
      await Fito().Plan.Log("cancel-subscription", this.cancelReason === 'Other' && this.cancelOtherReason?this.cancelOtherReason:this.cancelReason)
    },

    showSuccess(message) {
      this.success = message;
      setTimeout(() => this.success = '', 5000)
    },

  },

  computed : {
    canCancel() {
      return this.cancelReason.length && (this.cancelReason !== "Other" || this.cancelOtherReason)
    },
    canSavePassword() { return this.newPassword === this.newPassword2 && this.newPassword.length >= 8 },
    signupDate() {
      if (this.login.subscriber.subscription_date) {
        const date = new Date(this.login.subscriber.subscription_date);
        return date.getDate() + '/' + (date.getMonth()+1) + '/' + date.getFullYear()
      }
      return '';
    },
    renewalDate() {
      let date;
      if (this.login.subscriber.expiration_date) {
        date = new Date(this.login.subscriber.expiration_date);
        return date.getDate() + '/' + (date.getMonth()+1) + '/' + date.getFullYear()
      }
      else if (this.login.subscriber.subscription_date) {

        const interval = this.login.subscriber.charge_interval || 1

        date = new Date(this.login.subscriber.subscription_date);
        while (date.getTime() < new Date().getTime()) {
          date = new Date(date.getTime() + (1000*60*60*24*30) * interval)
          if (!this.login.renew) break;
        }
        return date.getDate() + '/' + (date.getMonth()+1) + '/' + date.getFullYear()
      }
      return '';
    },
  }
}
</script>
