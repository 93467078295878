<template>
  <div class="select-box">
    <div class="selected-option" v-if="!expanded" @click="toggleExpanded"><span><img v-if="selectedIcon" :src="selectedIcon" class="item-icon" /> {{selectedLabel}}</span><img :src="ChevronDown" class="chevron" /></div>
    <div class="options" v-if="expanded">
      <div v-for="(item,i) in items" :key="i" @click="() => selectItem(item)" :class="isSelected(item)?'selected':null">
        <img v-if="item.icon" :src="item.icon" class="item-icon" />
        <span>{{itemLabel(item)}}</span>
      </div>
    </div>
  </div>
</template>
<script>

import ChevronDown from "@/assets/icons/chevron-down.svg";

export default {
    name: "SelectBox",
    props: {
      items: Array,
      index: { type: Number, default: 0},
      selected: {type: [String,Number,null], default: null },
      onSelect: Function,
    },
    data() {
      return {
        expanded: false,
        ChevronDown,
      }
    },
  computed : {
    selectedLabel() {
      if (!this.selected) return '-'
      const item = this.items.find(it => it.value ? it.value === this.selected : it === this.selected)
      return item.label ? item.label : item
    },
    selectedIcon() {
      const item = this.items.find(it => it.value ? it.value === this.selected : it === this.selected)
      return item ? item.icon : false
    },
  },
  methods : {
      isSelected(item) {
        if (!this.selected) return false
        return item.value ? item.value === this.selected : item === this.selected
      },
      toggleExpanded() {
        this.expanded = !this.expanded
      },
      itemLabel(item) {
        if (item.label) return item.label
        return item.toString
      },
      selectItem(item) {
        if (item.value) this.onSelect(item.value)
        else this.onSelect(item)
        this.expanded = false
      },
  }
}
</script>
