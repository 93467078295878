import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import routes from '@/lib/routes'
import {initFito} from "@/lib/fito";
import './registerServiceWorker'


(async () => {

  const CLIENT_VERSION = "1.4"

  const online = await initFito('powerninja-live', process.env.VUE_APP_API_URL, CLIENT_VERSION, () => { setTimeout(() => router.push('/old-version'),10) });
  let appRoutes = routes
  if (online) {
    localStorage.removeItem('offline')
  }
  else {
    console.log("!!")
    localStorage.setItem('offline', '1')
    appRoutes = routes.filter(it => it.path === '/offline').map(it => ({...it, path: '*'}))
    console.log(appRoutes)
  }

  const router = new VueRouter({
    mode: 'history',
    routes : appRoutes,
  });

  Vue.use(VueRouter);
  Vue.config.productionTip = false

  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app')
})();
