<template>
  <label>
    <span v-if="label">{{label}}</span>
    <div class="input-wrapper">
      <div class="check-holder" v-if="validate"><img :src="CheckIcon" :class="isValid(val, type) ?'active':''" /></div>
      <component :is="inputTag" :type="inputType" tabindex="2" :value.prop="val" @input="onInput" :placeholder="placeholder" />
    </div>
  </label>
</template>

<script>

import CheckIcon from "@/assets/icons/check.svg";


function cleanPhone(num) {
  return num.replace(/([ \-_])/g,'')
}

export default {
  name: "InputWrapper",
  props: {
    validate: {type: Boolean, default: true},
    label: { type: String, default: "" },
    value: { type: [String, Number, Boolean] },
    type : { type: String, default: "text"},
    pass1 : { type: String, default: ""},
    placeholder : { type: String, default: ""},
    validator : {type: Function, default: null}
  },
  data() {
    return {
      CheckIcon,
      val: ''
    }
  },

  mounted() {
    this.val = this.value
  },

  computed : {
    inputType() {
      switch (this.type) {
        case 'password':
        case 'password2':
          return 'password';
        case 'number': return 'number';
        default:
          return 'text'
      }
    },
    inputTag() {
      switch (this.type) {
        case 'textarea': return 'textarea';
        default:
          return 'input'
      }
    }
  },
  methods : {
    onInput(e) {
      this.$emit("input", e.target.value)
    },
    isValid : function(val, type) {
      if (this.validator) return this.validator(val, type)
      switch (type) {
        case 'email': return val.indexOf('@') > 0 && val.lastIndexOf(".") > val.lastIndexOf('@') && val.lastIndexOf(".") < val.length-2;
        case 'phone': return !isNaN(cleanPhone(val)*1) && cleanPhone(val).length >= 10 && cleanPhone(val).length <= 15;
        case 'password': return val.length >= 8;
        case 'password2': return val.length >= 8 && this.value === this.pass1;
        case 'subject': return val.length > 1;
        case 'name': default: return val.length > 1;
      }
    },
  },
  watch : {
    value() {
      this.val = this.value
    }
  }
}
</script>
