<template>
  <Modal class="customize-rest-day" :dismiss="dismiss">
    <h3 v-if="title">{{title}}</h3>
    <h4>{{t("Set your activity:")}}</h4>
    <SelectBox :items="items" :on-select="setRestDayActivity" :selected="activities[0]" v-if="!blockBecauseRestDays" />
    <div v-if="blockBecauseRestDays">
      <Flexy v-if="items && items.find(it=>it.value===activities[0])">
        <img v-if="items.find(it=>it.value===activities[0]).icon" :src="items.find(it=>it.value===activities[0]).icon" />
        {{items.find(it=>it.value===activities[0]).label}}
      </Flexy>
      <div class="rest-warning">
        {{t("rest-warning-text")}}
      </div>
    </div>
    <Flexy v-for="(activity, i) in moreActivities" :key="i">
      <SelectBox :items="additionalItems" :on-select="(activity) => setRestDayActivity(activity, i+1)" :selected="activity" />
      <button @click="removeActivity(i+1)"><img :src="XIcon" /></button>
    </Flexy>
    <a class="add-activity-link" @click="addActivity" v-if="!blockBecauseRestDays"><img :src="PlusIcon" /> {{t("Add Activity")}}</a>

    <div class="btn-group">
      <button @click="dismiss">{{t("Cancel")}}</button>
      <button class="active" @click="save">{{t("Save")}}</button>
    </div>
    <Flexy>
      <Checkbox :on-select="toggleAhead" :checked="customizeAhead" /><span>{{t("Set for following weeks too")}}</span>
    </Flexy>
  </Modal>
</template>
<script>

import t from '@/lib/Locale'
import Modal from "@/components/ui/widgets/Modal";
import RestingIcon from '@/assets/icons/plan/training-rest.svg';
import NinjaIcon from '@/assets/icons/plan/training-ninja.svg';
import OtherPracticeIcon from '@/assets/icons/plan/training-other.svg';
import PlusIcon from '@/assets/icons/plus-icon.svg';
import XIcon from '@/assets/icons/x-red.svg';
import SelectBox from "@/components/ui/widgets/SelectBox";
import Flexy from "@/components/ui/widgets/Flexy";
import Checkbox from "@/components/ui/widgets/Checkbox";

let items = [
  {label: t("Rest Day"), value: "rest", icon: RestingIcon },
  {label: t("Ninja Practice"), value: "ninja", icon: NinjaIcon },
  {label: t("Another Practice"), value: "other", icon: OtherPracticeIcon },
]

export default {
  name: "DayActivityModal",
  components: {Checkbox, Flexy, SelectBox, Modal},
  props: {
    title: String,
    dismiss: Function,
    onActivity : Function,
    activity: Array,
    restDays: Number,
  },
  data() {
    return {
      t,
      PlusIcon, XIcon,
      items,
      activities : [],
      expanded: -1,
      customizeAhead: false
    }
  },
  mounted() {
    this.customizeAhead = false
    if (this.activity) this.activities = this.activity;
    if (typeof this.activities === "string") this.activities = [this.activities]
  },
  computed : {
    blockBecauseRestDays() {
      return this.restDays <= 1 && this.activities[0] === 'rest' || !this.activities[0]
    },
    moreActivities() {
      return this.activities.slice(1)
    },
    additionalItems() {
      return this.items.filter(it => it.value !== 'rest')
    }
  },
  methods : {
    save() {
      if (this.onActivity) this.onActivity(this.activities, this.customizeAhead)
    },
    toggleAhead() { this.customizeAhead = !this.customizeAhead },
    addActivity() {
      const activities = [...this.activities]
      activities.push(this.additionalItems[0].value)
      this.activities = activities;
    },
    removeActivity(i) {
      const activities = [...this.activities]
      activities.splice(i, 1)
      this.activities = activities
    },
    setRestDayActivity(activity, index = 0) {
      if (activity === "rest" && index === 0) { // can be just reset to rest
        return this.activities = ['rest']
      }
      console.log({activity, index})
      this.$set(this.activities, index, activity)
      this.expanded = -1
    },

  }
}
</script>
