<template>
  <div class="plan-wrapper">
    <div class="skill-test-wrapper" v-if="inited">
      <Vimeo path="776690714" />
      <h2>{{t("skill-test-title")}}</h2>
      <p>{{t("skill-test-content")}}</p>
      <div class="skill-test-form">
        <input type="number" :placeholder="t('skill-test-input-placeholder-1')" :value="pulls" @change="(e) => onSetNum(e,'pulls')" @blur="(e) => onSetNum(e,'pulls')" min="0" max="999" maxlength="2" />
        <input type="number" :placeholder="t('skill-test-input-placeholder-2')" :value="hold_both" @change="(e) => onSetNum(e,'hold_both')" @blur="(e) => onSetNum(e,'hold_both')" min="0" max="999" maxlength="2" />
        <input type="number" :placeholder="t('skill-test-input-placeholder-3')" :value="hold_right" @change="(e) => onSetNum(e,'hold_right')" @blur="(e) => onSetNum(e,'hold_right')" min="0" max="999" maxlength="2" />
        <input type="number" :placeholder="t('skill-test-input-placeholder-4')" :value="hold_left" @change="(e) => onSetNum(e,'hold_left')" @blur="(e) => onSetNum(e,'hold_left')" min="0" max="999" maxlength="2" />
        <div class="confirm-row"><Checkbox :checked="confirm_1" :on-select="(e) => confirm(1, e)" /><span>{{t('skill-test-confirm-1')}}</span></div>
        <div class="confirm-row"><Checkbox :checked="confirm_2" :on-select="(e) => confirm(2, e)" /><span>{{t('skill-test-confirm-2')}}</span></div>
        <div class="confirm-row"><Checkbox :checked="confirm_3" :on-select="(e) => confirm(3, e)" /><span>{{t('skill-test-confirm-3')}}</span></div>
        <div :class="'btn'+(canSubmit?' active':'')" @click="onSubmit">{{t("Submit Test")}}</div>
      </div>
    </div>

  </div>
</template>
<script>
import t from "@/lib/Locale"
import Vimeo from "@/components/ui/widgets/Vimeo";
import Checkbox from "@/components/ui/widgets/Checkbox";
import {dateToString, getCurrentTrainingDays, hadTests, nextTestDate} from "@/lib/DiaryUtils";
import {clearLocalCaches, getLoginDetails, getPlanCurrentPeriodDate, setTestScore} from "@/lib/PlanUtils";
export default {
  name: "SkillTest",
  components: {Checkbox, Vimeo},
  data() {
    return {
      t,
      meta: null,
      subscriber: null,
      currentPeriodDate: null,
      pulls: "",
      hold_both: "",
      hold_right: "",
      hold_left: "",
      confirm_1: false,
      confirm_2: false,
      confirm_3: false,

      inited: false,
    }
  },
  async mounted() {
    const login = await getLoginDetails('/plan/login')
    this.meta = login.meta
    this.subscriber = login.subscriber
    this.currentPeriodDate = getPlanCurrentPeriodDate(login.subscriber, login.meta)

    if (!this.pendingTest) {
      return this.$router.push('/plan/home')
    }
    else {
      // reset caches here to remove old user data
      await clearLocalCaches()
      this.inited = true
    }

  },
  computed : {
    pendingTest() {
      const testDate = nextTestDate(this.meta, this.currentPeriodDate, getCurrentTrainingDays(this.meta))
      if (dateToString(testDate) <= dateToString(new Date())) {
        return true
      }
      return hadTests(this.meta) ? false : true
    },
    canSubmit() {
      const {pulls, hold_both, hold_right, hold_left, confirm_1, confirm_2, confirm_3} = this
      if (pulls === "" || hold_left === '' || hold_both === '' || hold_right === '') return false
      return pulls*1 > 0 && hold_both && hold_both*1 > 0 && hold_right*1 > 0 && hold_left *1 > 0 && confirm_1 && confirm_2 && confirm_3
    }
  },
  methods : {
    confirm(which) {
      this.$set(this, 'confirm_'+which, !(this['confirm_'+which]))
    },
    async onSubmit() {
      if (!this.pulls || !this.canSubmit) return;
      const {pulls, hold_both, hold_right, hold_left} = this
      await setTestScore(this.subscriber, dateToString(new Date()), pulls, hold_both, hold_right, hold_left)
      await this.$router.push('/plan/signup-trainings')
    },
    onSetNum(e, prop) {
      if (e.target.value === '') return this.$set(this, prop, '')
      let v = e.target.value*1
      if (v < 0) v = 0
      if (v > 999) v = 999
      this.$set(this, prop, v.toString())
    }
  }
}
</script>
