<template>
  <div class="view-error container">
    <div class="error-logo"><img :src="Logo" /></div>
    <div class="plan-panel only">
      <h3>{{t('Error')}}</h3>
      <p>{{t("Could not reach our servers. Are you offline?")}}</p>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale"
import Logo from '@/assets/images/gorilla-logo.svg';

export default {
  name: "Offline",
  async mounted() {
    history.pushState({},'','/')
  },
  data() {
    return {
      Logo
    }
  },
  methods : {
    t,
  }
}
</script>
