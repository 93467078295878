<template>
  <div>
    <div class="stats-row compact">
      <div class="stat">
        <div class="stat-title" v-if="label">
          <img v-if="icon" :src="icon" />
          <h3>{{t(label)}}</h3>
        </div>
        <Flexy :between="true">
          <p v-for="(stat, i) in stats" :key="i" class="value-wrapper"><img :src="stat.icon" v-if="stat.icon" /><span v-if="stat.label">{{t(stat.label)}}</span><b class="value">{{stat.value?stat.value:stat}}</b></p>
        </Flexy>
      </div>
    </div>
    <div class="flexy centered">
      <button class="active small" v-if="allowGraphs" @click="toggleGraphs">{{t(showGraph?"Hide Graphs":"Show Graphs")}}</button>
    </div>
    <div v-if="showGraph" class="stats-row">
      <div class="stat">
        <TestsChart :items="graphData" />
      </div>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale'
import Flexy from "@/components/ui/widgets/Flexy";
import TestsChart from "@/components/ui/widgets/TestsChart.vue";

export default {
  name: "PlanStatsRow",
  components: {TestsChart, Flexy},
  props: {
    icon: String,
    label: String,
    stats: Array,
    allResults: Array,
  },
  data() {
    return {
      t,
      showGraph: false,
    }
  },
  computed : {
    allowGraphs() { return this.allResults && this.allResults.length >= 2 },
    graphData() {
      return this.allResults.map(it => {delete it.date; return it })
    }
  },
  methods : {
    toggleGraphs() { this.showGraph = !this.showGraph },
  }
}
</script>
