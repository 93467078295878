import Landing from "@/components/views/landing/Landing";
import Quiz from "@/components/views/landing/Quiz";
import Contact from "@/components/views/common/Contact";
import Results from "@/components/views/landing/Results";
import Login from "@/components/views/login/Login";
import MyProfile from "@/components/views/plan/MyProfile";
import Signup from "@/components/views/signup/Signup";
import SignupComplete from "@/components/views/signup/SignupComplete";
import ResetPassword from "@/components/views/login/ResetPassword";
import PlanHome from "@/components/views/plan/PlanHome";
import WeeklyPlan from "../components/views/plan/diary/WeeklyPlan";
import EditProfile from "@/components/views/plan/profile/EditProfile";
import AccountDetails from "@/components/views/plan/profile/AccountDetails";
import TrainingDays from "@/components/views/plan/profile/TrainingDays";
import ChooseTrainingDays from "@/components/views/plan/setup/ChooseTrainingDays";
import SkillTest from "@/components/views/plan/setup/SkillTest";
import DailyPlan from "@/components/views/plan/diary/DailyPlan";
import ContactUs from "@/components/views/plan/profile/ContactUs";
import LibraryList from "@/components/views/plan/library/LibraryList";
import GearSelection from "@/components/views/plan/profile/GearSelection";
import Logout from "@/components/views/login/Logout";
import TrainingView from "@/components/views/plan/diary/TrainingView";
import StartPlanNextWeek from "@/components/views/plan/setup/StartPlanNextWeek";
import VersionError from "@/components/views/common/VersionError";
import Offline from "@/components/views/common/Offline.vue";
import LivePractice from "@/components/views/plan/LivePractice.vue";

export default [
    // Landing
    { path: '/', component: Landing, keepAlive: true },
    { path: '/quiz', component: Quiz, keepAlive: true },
    { path: '/contact', component: Contact, meta: { form: true }, keepAlive: true },
    { path: '/results', component: Results, keepAlive: true },
    { path: '/recover-password/:code', component: ResetPassword, meta: { form: true }, keepAlive: true },
    { path: '/old-version', component: VersionError, meta: { form: true }, keepAlive: true },
    { path: '/offline', component: Offline, meta: { form: true }, keepAlive: true },


    // Plan
    { path: '/plan/logout', component: Logout, meta: { form: true }, keepAlive: true },
    { path: '/plan/login', component: Login, meta: { form: true }, keepAlive: true },
    { path: '/plan/signup', component: Signup, meta: { form: true }, keepAlive: true },
    { path: '/plan/signup/:code', component: Signup, meta: { form: true }, keepAlive: true },
    { path: '/plan/signup-trainings', component: ChooseTrainingDays, keepAlive: true },
    { path: '/plan/signup-complete', component: SignupComplete, keepAlive: true },
    { path: '/plan/signup-next-week', component: StartPlanNextWeek, keepAlive: true },
    { path: '/plan/test', component: SkillTest, keepAlive: true },


    { path: '/plan', redirect: "/plan/home" },
    { path: '/plan/home', component: PlanHome, keepAlive: true },
    { path: '/plan/weekly-plan', component: WeeklyPlan, keepAlive: true },
    { path: '/plan/daily/:date', component: DailyPlan, keepAlive: true, meta: { title: "Daily Training" }  },
    { path: '/plan/training', component: TrainingView, keepAlive: true, meta: { title: "Training" }  },
    { path: '/plan/library', component: LibraryList, keepAlive: true },
    { path: '/plan/profile', component: MyProfile, keepAlive: true },
    { path: '/plan/profile/contact-us', component: ContactUs, meta: { title: "Contact Us" }, keepAlive: true },
    { path: '/plan/profile/trainings', component: TrainingDays, keepAlive: true, meta: { title: "Training Days" } },
    { path: '/plan/profile/gear', component: GearSelection, keepAlive: true, meta: { title: "Gear" } },
    { path: '/plan/profile/edit-details', component: EditProfile, keepAlive: true, meta: { title: "Edit Profile" } },
    { path: '/plan/profile/account-details', component: AccountDetails, keepAlive: true, meta: { title: "Account Details" } },

    // live event
    { path: '/plan/live-stream/:id', component: LivePractice, keepAlive: true},

    // account
    { path: '/plan/account', component: MyProfile, meta: { form: true }, keepAlive: true },

    { path: '*', redirect: "/" }

]
