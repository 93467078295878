<template>
  <router-link :to="nextPracticeLink">
    <h4>{{t("Your next training")}}</h4>
    <div class="stats-row">
      <div class="stat">
        <Flexy :between="true">
          <b>{{nextPractice}}<span v-if="nextPracticeCompleted" class="success-notice">{{t("Completed")}}</span></b>
          <p><img :src="TrainingsIcon" /> {{nextPracticeTrainings}}</p>
          <p><img :src="DifficultyIcon" /> {{nextPracticeDifficulty}}</p>
        </Flexy>
      </div>
    </div>
  </router-link>
</template>


<script>
import DifficultyIcon from '@/assets/icons/plan/diary-difficulty.svg';
import TrainingsIcon from '@/assets/icons/plan/diary-trainings.svg';
import {dateToString} from "@/lib/DiaryUtils";
import t from "@/lib/Locale";
import Flexy from "@/components/ui/widgets/Flexy.vue";
let days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]

export default {
  name: "NextPracticeBar",
  components: {Flexy},
  props: {
    schedule: Array,
    completedPractices: Array,
  },
  data() {
    return {
      t, DifficultyIcon, TrainingsIcon,
    }
  },
  computed : {
    nextPractice() {
      let today = dateToString(new Date())
      for (let practice of this.schedule) {
        if (dateToString(practice.date) >= today) {
          let dayDiff = Math.round((practice.date - new Date()) / 1000/60/60/24)
          switch (dayDiff) {
            case 0: return t("Today")
            case 1: return t("Tomorrow")
            case 2: case 3: case 4: case 5: case 6: return t(days[practice.date.getDay()])
          }
          break
        }
      }
      return ''
    },

    nextPracticeCompleted() {
      let today = dateToString(new Date())
      for (let practice of this.schedule) {
        if (dateToString(practice.date) >= today) {
          if (this.completedPractices instanceof Array)
            return this.completedPractices.find(it => it.date === dateToString(practice.date))
        }
      }
      return false
    },

    nextPracticeDifficulty() {
      let today = dateToString(new Date())
      for (let practice of this.schedule) {
        if (dateToString(practice.date) >= today) {
          return t(practice.difficulty)
        }
      }
      return ''
    },
    nextPracticeTrainings() {
      let today = dateToString(new Date())
      for (let practice of this.schedule) {
        if (dateToString(practice.date) >= today) {
          return t("{0} trainings", practice.trainings.length)
        }
      }
      return ''
    },
    nextPracticeLink() {
      let today = dateToString(new Date())
      for (let practice of this.schedule) {
        if (dateToString(practice.date) >= today) {
          return '/plan/weekly-plan?hl='+dateToString(practice.date)
        }
      }
      return '/plan/weekly-plan'
    },
  }
}
</script>
