<template>
  <div class="stat">
    <div class="stat-title">
      <img v-if="icon" :src="icon" />
      <h3>{{t(label)}}</h3>
    </div>
    <h1>{{value}}</h1>
  </div>
</template>

<script>
import t from '@/lib/Locale'

export default {
  name: "PlanStat",
  props: {
    icon: String,
    label: String,
    value: {type: [String, Number]}
  },
  data() {
    return {
      t,
    }
  }
}
</script>
