<template>
  <div class="landing-wrapper">
    <div class="container-m landing-box">
      <div class="quiz-logo"><img :src="Logo" alt="Logo" /></div>
      <div class="header">
        <div class="centered">
          <h2>{{t("Welcome!")}}</h2>
          <p>{{t("welcome-text")}}</p>
        </div>
      </div>
      <div class="body">
        <router-link to="/quiz" class="btn active">{{t("Start")}} <img :src="leftArrowIcon" class="arrow-icon" /></router-link>
      </div>
    </div>
    <div class="footer">
      <p>{{t("Already have an account?")}} <router-link to="/plan/login">{{t("Sign In")}}</router-link></p>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale'
import Logo from '@/assets/images/gorilla-logo.svg';
import leftArrowIcon from '@/assets/icons/arrow-left.svg';

import Fito from "@/lib/fito";

export default {
name: "Landing",
  async mounted() {
    if (localStorage.getItem('login-token')) {
      const login = await Fito().Login.CheckLogin();
      if (login.subscriber) window.location.href = '/plan/home';
      return false;
    }
  },
  data() {
    return {
      Logo,
      leftArrowIcon
    }
  },
  methods : {
    t,
    start() {
      this.$router.push('/quiz')
    }
  }
}
</script>
