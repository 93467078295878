<template>
  <div id="app">
    <Navbar v-if="!BUSY_MOCKUP && insidePlan" :inside-page="insidePage" :is-landing="isLanding" :logged-in="loggedIn" :subscriber="subscriber" :on-logout="onLogout" />
    <div :class="viewClass" v-if="!BUSY_MOCKUP">
      <router-view @stats="onStats" :stats="stats" :meta="meta" @meta="onMeta" />
    </div>
    <SiteBusy v-if="BUSY_MOCKUP" />
  </div>
</template>

<script>

import SiteBusy from "@/components/views/common/SiteBusy";
const BUSY_MOCKUP = false;

import 'hooper/dist/hooper.css';

import './scss/style.scss';
import Navbar from "@/components/ui/navbar/Navbar";
import Fito from "@/lib/fito";
export default {
  name: 'App',
  components: {SiteBusy, Navbar,  },
  data() {
    return {
      BUSY_MOCKUP,
      stats: null,
      loggedIn: false,
      meta : {},
      subscriber : null
    }
  },
  async mounted() {

    Fito().Login.onLogin(this.onLogin)
    Fito().Login.onLogout(this.onLogout)

    if (window.location.href.indexOf('secret-special') > -1) {
      localStorage.setItem('secret-special', Math.round(new Date().getTime()/1000)+'')
    }

  },
  computed : {
    currentRoute() { return this.$route.path },
    insidePlan() { return this.currentRoute.indexOf('/plan') > -1 && ['/plan/login', '/plan/signup'].indexOf(this.currentRoute) === -1},
    isPostSignup() { return this.currentRoute.indexOf('/plan/signup-complete') > -1 },
    isLanding() { return this.currentRoute === '/' || this.currentRoute === '/quiz'},
    isTest() { return this.currentRoute.indexOf('/plan/test') > -1},
    viewClass() {
      return 'view'
          + ((this.loggedIn || this.isTest) ? ' view-logged-in':'')
          + (this.isLanding? ' view-landing':'')
          + (this.isPostSignup? ' view-post-signup':'')
          + (this.$route.meta && this.$route.meta.form ? ' form':'')
    },
    insidePage() {
      if (this.currentRoute.match(/\/plan\/profile\/([A-Za-z])+/)) return '/plan/profile/'
      if (this.currentRoute.match(/\/plan\/daily\/([0-9-])+/)) return '/plan/weekly-plan'
      if (this.currentRoute.match(/\/plan\/training/)) return '/plan/daily/'+localStorage.getItem('current-date')
      return false
    },
  },
  methods : {
    onStats(stats) {
      this.stats = stats
    },

    onMeta(key, value) {
      this.meta[key] = value
    },


    onLogin(login, isRealLogin = false) {
      const wasLoggedIn = this.loggedIn
      if (login) {
        this.loggedIn = true
        if (login.subscriber) this.subscriber = login.subscriber
        if (login.meta) this.meta = login.meta;
        if (!wasLoggedIn) {
          Fito().Plan.Log(isRealLogin?'login':'reload')
        }
      }
    },

    onLogout() {
      this.loggedIn = false
      localStorage.clear()
      this.$router.push('/plan/login')
    }
  }
}
</script>
