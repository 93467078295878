<template>
  <div :class="containerCls">
    <iframe v-if="path && ready" :src="iframeSrc" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" :style="frameStyle" title="פתיח מתחילים - מירן"></iframe>
  </div>
</template>

<script>
export default {
  name: "Vimeo",
  props : {
    path : {type: [String,Number]},
    isEvent: { type: Boolean, default: false},
    wrapped: { type: Boolean, default: false},
  },
  data() {
    return {
      ready: false
    }
  },
  mounted() {
    setTimeout(() => this.ready = true,100)
  },
  computed : {
    containerCls() {
      let c = 'vimeo-container'
      if (!this.ready) c += ' loading'
      if (this.wrapped) c += ' wrapped'
      return c
    },
    frameStyle() {
      return {
        position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
      }
    },
    iframeSrc() {
      if (this.isEvent) return 'https://vimeo.com/event/'+this.path+'/embed'
      else return 'https://player.vimeo.com/video/'+this.path+"?h=9eb55907fb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    }
  },
  watch : {
    path(newPath) {
      this.ready = false
      setTimeout(() => this.ready = true,100)

      console.log(newPath)
    }
  }
}
</script>
