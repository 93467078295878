<template>
  <div class="plan-wrapper">
    <div class="success-bar" v-if="success"><p>{{success}}</p></div>
    <Flexy class="plan-centered-info">{{t("plan-trainings-intro")}}</Flexy>
    <WeekdaySelector :selected="trainingDays" :on-select="toggleTrainingDay" />
    <div :class="'toggle-error' + (toggleError?' active':'')">{{toggleError}}</div>
    <div class="plan-actions">
      <router-link class="btn" to="/plan/profile">{{t("Cancel")}}</router-link>
      <div class="btn active" @click="saveDays">{{t("Save")}}</div>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import WeekdaySelector from "@/components/plan/WeekdaySelector";
import Flexy from "@/components/ui/widgets/Flexy";
import {saveTrainingDays} from "@/lib/DiaryUtils";
import Fito from "@/lib/fito";

export default {
  name: "TrainingDays",
  components: {Flexy, WeekdaySelector},

  data() {
    return {
      t,
      success: null,
      toggleError: null,
      toggleClear : null,
      trainingDays : [0,2,4]
    }
  },

  mounted() {
    let savedDays = localStorage.getItem('training-days')
    if (savedDays) {
      savedDays = JSON.parse(savedDays)
      this.trainingDays = savedDays[savedDays.length-1].days
    }
  },

  methods : {
    async saveDays() {
      await saveTrainingDays(this.trainingDays)
      this.success = t("Saved settings");
      clearTimeout(this.successTimeout)
      this.successTimeout = setTimeout(() => {
        this.success = null
      }, 2000)
      await Fito().Login.CheckLogin(true)
    },

    setToggleError(error) {
      this.toggleError = t(error)
      clearTimeout(this.toggleClear)
      this.toggleClear = setTimeout(() => {
        this.toggleError = null
      }, 2000)
    },

    toggleTrainingDay(day) {
      this.toggleError = null
      let toBe = [...this.trainingDays]
      if (toBe.indexOf(day) > -1) {
        // ...
        toBe.splice(toBe.indexOf(day), 1)
      }
      else {
        toBe.push(day)
      }
      toBe = toBe.sort()
      // scan in a row
      let inARow = 0
      if (toBe.length > 3) {
        this.setToggleError("3 power trainings is the maximum amount in a week")
        return
      }
      for (let i = 0; i < 10; i++) {
        if (toBe.indexOf(i%7) > -1) inARow++
        else inARow = 0
        if (inARow > 2) {
          this.setToggleError("No more than 2 power trainings in a row are allowed")
          return
        }
      }
      this.trainingDays = toBe;
    }
  }

}
</script>
