<template>
  <div class="plan-wrapper">
    <Vimeo :is-event="true" :path="vidId" />
  </div>
</template>

<script>

import t from '@/lib/Locale';
import Vimeo from "@/components/ui/widgets/Vimeo";
import {getLoginDetails} from "@/lib/PlanUtils";
import Fito from "@/lib/fito";

export default {
  name: "LivePractice",
  components: { Vimeo},
  props: ['meta'],
  data() {
    return {
      subscriber: null
    }
  },

  async mounted() {
    const login = await getLoginDetails('/plan/login', null, true)
    this.subscriber = login.subscriber;

    Fito().Plan.Log('watched-live-stream', this.$route.params.id.toString() )
  },

  computed : {
    vidId() { return this.$route.params.id.toString() }
  },

  methods : {
    t,

  }

}
</script>
