<template>
  <span>
  <div :class="clsName" @click="onClick" v-if="!ended">
    {{textIntro}} <span v-if="!started">{{time}}</span>
  </div>
  </span>
</template>

<script>
import t from "@/lib/Locale";
export default {
  name: "LiveNotification",
  props : {
    starts: {type: String },
    ends: {type: String },
    path : {type: String}
  },
  data() {
    return {
      time: null,
      started: false,
      ended: false,
    }
  },
  mounted() {
    this.timeout = setInterval(this.tick, 1000)
    this.tick()
  },
  beforeDestroy() {
    clearInterval(this.tick)
  },

  computed : {
    textIntro() {
      return t(this.started?"Live practice started, join the stream now!":"Live Practice starts in:")
    },
    clsName() {
      let cls = 'live-notification';
      if (this.started) cls += ' online'
      return cls
    }
  },

  methods : {
    t,
    onClick() {
      this.$router.push('/plan/live-stream/'+this.path)
    },
    tick() {

      let d = this.starts.split(/[- :]/);
          d = new Date(d[0], d[1]-1, d[2], d[3], d[4], d[5])
          let now = new Date(), diff = Math.round((d.valueOf() - now.valueOf())/1000)


      this.started = this.ended = false
      if (diff < 0) {
        this.started = true
        d = new Date(this.ends), diff = Math.round((d.valueOf() - now.valueOf()) / 1000)
        if (diff < 0) this.ended = true
        return
      }


      let days = Math.floor(diff / 86400);
      diff -= days * 86400;
      let hours = Math.floor(diff / 3600) % 24;
      diff -= hours * 3600;
      let minutes = Math.floor(diff / 60) % 60;
      diff -= minutes * 60;
      let seconds = Math.floor(diff % 60);


      if (minutes < 1) return this.time = t("{0} seconds", seconds)
      else if (hours < 1) return this.time = t("{0} minutes, {1} seconds", minutes, seconds)
      else if (days < 1) return this.time = t("{0} hours, {1} minutes, {2} seconds", hours, minutes, seconds)
      else return this.time = t("{0} days, {1} hours, {2} minutes, {3} seconds", days, hours, minutes, seconds)
    }
  },


}
</script>
