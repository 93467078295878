<template>
  <div class="view-content quiz-wrapper">

    <stages-bar v-if="active" :quiz-index="quizIndex" :questions="questions" :on-change="onChange" />
    <div class="prev-wrapper" v-if="prev">
      <div class="questions-slider">
        <div :class="'slider-inner' + (prev?' prev':'')">
            <QuizQuestion v-if="prev && active" :on-answer="onAnswer" :results="answers" :goto="goto"
                       :icon="prev.icon" :question="prev.question" :type="prev.type" :answers="prev.answers" :info="prev.info" :min="prev.min" :max="prev.max" />
        </div>
      </div>
    </div>
    <div :class="'current' + (prev?' anim':'')" v-if="!loadingResults">
      <div :class="questionSliderClass">
        <div :class="'slider-inner' + (prev?' prev':'')">
      <QuizQuestion v-if="question && active" :on-answer="onAnswer" :results="answers" :goto="goto"
                 :icon="question.icon" :question="question.question" :type="question.type" :answers="question.answers" :info="question.info" :min="question.min" :max="question.max" />
          <Flexy>
          <button v-if="isNotification" class="flex-1 active" @click="restartQuiz">{{t("Start Over")}}</button>
          </Flexy>
        </div>
      </div>
    </div>
    <LoadingResults v-if="loadingResults" />

  </div>
</template>

<script>
import questions from "@/lib/docs/SignupQuizQuestions";
import StagesBar from "@/components/ui/StagesBar";
import QuizQuestion from "@/components/quiz/QuizQuestion";
import LoadingResults from "@/components/quiz/LoadingResults";
import t from '@/lib/Locale';
import Flexy from "@/components/ui/widgets/Flexy";


export default {
  name: "Quiz",
  props: ['stats'],
  components: {Flexy, StagesBar, QuizQuestion, LoadingResults },
  data() {
    return {
      prev: null,
      quizIndex : 0,
      loadingResults : false,
      confirmed : false,
      pseudoLoaded : false,
      questions,
      answers : {},
      checkInterval: 4000,
      active: false
    }
  },
  computed : {
    question() { return this.questions[this.quizIndex] },
    questionSliderClass() {
      let cls = 'questions-slider'
      if (this.prev) cls += ' anim'
      if (this.questions.length - this.quizIndex === 2) cls += ' nearly-last'
      else if (this.questions.length - this.quizIndex === 1) cls += ' last'
      else if (this.question.notification) cls += ' notification'

      return cls
    },
    isNotification() {
      return !!this.question.notification
    }
  },

  async mounted() {
    if (localStorage.getItem('login-token')) {
      // window.location.href = '/plan/home';
      localStorage.removeItem('login-token')
    }

    setTimeout(() => this.active = true, 250)
    this.next = (this.quizIndex < this.questions.length -1) ? this.questions[this.quizIndex+1] : null

  },

  methods : {
    t,
    goto(prop) {
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].prop === prop) {
          this.quizIndex = i;
          return;
        }
      }
    },
    restartQuiz() {
      this.answers = {}
      this.quizIndex = 0
    },
    onAnswer(answer) {
      this.answers[this.question.prop] = answer;
      if (this.quizIndex < this.questions.length -1) {
        this.prev = {...this.question}
        this.quizIndex++;
        this.next = (this.quizIndex < this.questions.length -1) ? this.questions[this.quizIndex+1] : null
        if (this.question.condition) {
          while (this.question.condition && !this.question.condition(this.answers)) {
            this.quizIndex++;
            this.next = (this.quizIndex < this.questions.length -1) ? this.questions[this.quizIndex+1] : null
          }
        }
        setTimeout(() => this.prev = null, 501)
      }
      else {
        this.quizIndex = this.questions.length
        this.loadingResults = true;
        this.checkInterval = 4000;
        this.waitForConfirmation();
      }
    },

    waitForConfirmation() {

      localStorage.setItem('QuizAnswers', JSON.stringify(this.answers))

      setTimeout(async() => {
          const a = JSON.parse(localStorage.getItem('QuizAnswers'))
          console.log(a)
          if (a.bar) await this.$router.push("/results");
          else {
            alert("Error 194: Could not save quiz!")
          }
      }, this.checkInterval);
    },

    onChange(index) {
      if (index >= 0 && index < this.questions.length) this.quizIndex = index;
    }
  }
}
</script>
