<template>
  <div class="plan-wrapper">
    <div class="daily-training-info" v-if="today && today.trainings">
      <h3>{{dayTitle}}</h3>
      <div class="infos">
        <p><img :src="ClockIcon" /><span>{{t("{0} min.", today.trainings.length * 5)}}</span></p>
        <p><img :src="TrainingsIcon" /><span>{{t("{0} trainings", today.trainings.length)}}</span></p>
        <p><img :src="DifficultyIcon" /><span>{{t(today.difficulty)}}</span></p>
      </div>
    </div>
    <div class="daily-trainings" v-if="today && today.trainings">
      <div class="training warmup" @click="() => viewWarmup(warmup, i)" v-for="(warmup, i) in warmups" :key="'warmup_'+i">
        <img class="vid-thumb" :src="warmup.thumb" />
        <h3>
          <span>{{t("Warmup")}}:</span> {{ warmup.training }}
          <p>{{warmup.details}}</p>
        </h3>
      </div>
      <div class="training" v-for="(tr, i) in today.trainings" :key="i" @click="viewTraining(tr)">
        <img class="vid-thumb" :src="tr.thumb?tr.thumb:PlayBtn" />
        <h3>
          {{i+1}}. {{tr.training}}
          <p v-if="tr.details">{{tr.details}}</p>
        </h3>
      </div>
    </div>

    <div class="finished-training-wrapper">
      <div :class="'btn active' + (completed?' success':'')" @click="toggleCompleted"><img :src="CheckIcon" v-if="completed" /> {{t(completed?"Practice completed. Click to cancel.":"I finished the practice")}}</div>
    </div>
  </div>
</template>
<script>

import t from '@/lib/Locale'
import Fito from "@/lib/fito";
import {
  dateToString, getCurrentSkillLevel,
  getSunday,
  loadDiaryData,
  saveDiaryData
} from "@/lib/DiaryUtils";
import TrainingsIcon from '@/assets/icons/plan/diary-trainings.svg';
import DifficultyIcon from '@/assets/icons/plan/diary-difficulty.svg';
import CheckIcon from '@/assets/icons/check-white.svg';
import ClockIcon from '@/assets/icons/plan/diary-clock-active.svg';
import PlayBtn from '@/assets/icons/play-button.svg';
import {getDailyWarmups, getSubscriberSchedule, setCurrentTraining} from "@/lib/PlanUtils";

let days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]

export default {
  name: "DailyPlan",
  data() {
    return {
      TrainingsIcon, DifficultyIcon, ClockIcon, CheckIcon, PlayBtn,
      t,
      today: null,
      completed: false,
      completedPractices : [],
      meta : {}
    }
  },
  async mounted() {
    if (localStorage.getItem('login-token')) {
      const login = await Fito().Login.CheckLogin();
      if (!login.subscriber) {
        window.location.href = '/plan/login';
        return false;
      }

      const startDate = new Date(login.subscriber.subscription_date)

      this.planStartDate = new Date(startDate)

      this.subscriber = login.subscriber
      this.meta = login.meta
      this.weekDate = getSunday()

      this.schedule = await getSubscriberSchedule(login.subscriber, login.meta)

      const [completedPractices,] = loadDiaryData(login.meta)
      this.completedPractices = completedPractices

      this.today = this.schedule.find(it => dateToString(it.date) === this.date)
      this.completed = !!this.completedPractices.find(it => it.date === this.date)

      console.log(this.today)
    }
  },
  computed : {
    date() {
      return this.$route.params.date
    },
    dayTitle() {
      const d = new Date(this.date);
      return t(days[d.getDay()])+ ' '+ d.getDate()+'/'+(d.getMonth()+1)
    },
    skillLevel() {
      return getCurrentSkillLevel(this.meta) || 1;
    },

    warmups() {
      return getDailyWarmups(this.skillLevel)
    },

  },
  methods : {

    async viewWarmup(training, i) {
      training.warmupIndex = i
      await setCurrentTraining(training, this.date, this.today.trainings, this.warmups)
      await this.$router.push('/plan/training')
    },

    async viewTraining(training) {
      await setCurrentTraining(training, this.date, this.today.trainings)
      await this.$router.push('/plan/training')
    },
    async toggleCompleted() {
      this.completed = !this.completed;

      let c;
      if (this.completed) {
        c = [...this.completedPractices, {date: this.date, trainings: this.today.trainings.length }]
        await saveDiaryData(c)
      }
      else {
        c = [...this.completedPractices.filter(it => it.date !== this.date)]
        await saveDiaryData(c)
      }
    }
  }
}
</script>
