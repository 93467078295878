<template>
  <div class="panel-wrapper">
    <div class="container-m tight">
      <div class="header-logo small"><img :src="Logo" /></div>
      <div class="centered">
        <h2>{{t("Sign Up")}}</h2>
        <p>{{t("In order to join you need to sign up first")}}</p>
      </div>

    <div class="plan-panel" v-if="waitingForPaymentPage">
      <h3>{{t("One moment")}}</h3>
      <p>{{t("Transferring you to the payment page...")}}</p>
    </div>
    </div>
    <div class="container-m tight discounts">
      <SignupForm :on-submit="onSubmitDetails" :details="details" :error="error" v-if="!submittedDetails && !waitingForPaymentPage" />
      <ChoosePlanForm v-if="submittedDetails && token && !waitingForPaymentPage" :plans="filteredPlans" :on-cancel="cancelChoosePlan" :on-select="onChoosePlan" />
      <Flexy class="discount-bar" v-if="submittedDetails && token && !waitingForPaymentPage">
        <input type="text" :placeholder="t('Enter a discount code, if you have one.')" v-model="discountCode" v-if="submittedDetails && token && !waitingForPaymentPage" />
        <button @click="onDiscountCode" v-if="submittedDetails && token && !waitingForPaymentPage">{{t("Apply Discount")}}</button>
      </Flexy>
      <div v-if="discountError" class="error-bar">{{discountError}}</div>
      <div v-if="discountSuccess" class="success-bar">{{discountSuccess}}</div>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import SignupForm from "@/components/forms/plan/SignupForm";
import Fito from "@/lib/fito";
const paymentFormUrl = 'https://secure.cardcom.solutions/e/UgHL';
import Logo from '@/assets/images/gorilla-logo.svg';
import ChoosePlanForm from "@/components/forms/plan/ChoosePlanForm";
import Flexy from "@/components/ui/widgets/Flexy.vue";

const discountPrefix = 'ninja';
const discountSuffix= 'kids';
const normalPlanId = 6;


export default {
  name: "Signup",
  props: ["meta"],
  components: {Flexy, ChoosePlanForm, SignupForm},
  data() {
    return {
      paymentFormUrl, Logo,
      details : {},
      plans : [],
      token: null,
      submittedDetails: false,
      waitingForPaymentPage: false,
      error: '',
      discountCode: '',
      discountError: '',
      discountSuccess: '',
      discountApplied: false
    }
  },

  async mounted() {
    let token = this.$route.params && this.$route.params.code ?
        this.$route.params.code :
        localStorage.getItem('SignupToken');

    if (token) {
      this.details = await Fito().Signup.RecoverDetails(token)
      this.token = token;
    }

    this.plans = await Fito().Signup.GetPlans();
    const answers = JSON.parse(localStorage.getItem('QuizAnswers'))
    if (!token && (!answers)) return this.$router.push('/')

  },

  computed :{
    filteredPlans() {
      if (!this.plans) return [];
      if (this.discountApplied) return this.plans.map(
        plan => {
          if (plan.id !== normalPlanId) {
            plan.discount = true;
          }
          return plan;
        }
      )
      return this.plans.filter(p => p.id === normalPlanId)
    }
  },

  methods : {
    t,

    onDiscountCode() {
      if (!this.discountCode) return;



      if (this.discountCode.toLowerCase() === discountPrefix + discountSuffix) {
        this.discountApplied = true
        this.discountSuccess = t('Discount Applied, new plans available');
        setTimeout(() => {
          this.discountSuccess = ''
        }, 3000)
      }
      else {
        this.discountError = t('Invalid discount code')
        setTimeout(() => {
          this.discountError = ''
        }, 3000)
      }
    },

    async onSubmitDetails(first_name, last_name, email, password) {

      if (password.length < 8) {
        this.error = t('Your password must contain at least 8 letters')
        return false;
      }

      if (this.submittedDetails) return;
      this.submittedDetails = true

      this.details = {
        first_name, last_name, email, password
      }

      const res = await Fito().Signup.Signup(first_name, last_name, email, password, 'm', this.token);
      if (res.token) {
        if (res.salted) {
          console.log("Signup Salted Token:", res.salted)
          localStorage.setItem('SignupToken', res.salted)
          this.token = res.salted;
        }
        else {
          console.log("Signup Token:", res.token)
          localStorage.setItem('SignupToken', res.token)
          this.token = res.token;
        }
      }
      else {
        console.error("Signup Error!", res)
        this.error = res.error;
        alert("Something went wrong, try again!")
      }
    },

    cancelChoosePlan() {
      this.submittedDetails = false
    },

    async onChoosePlan(plan) {
      await Fito().Signup.setPlan(this.token, plan.id)
      this.waitingForPaymentPage = true
      setTimeout(() => {
        console.log("Token is:", this.token)
        let suffix = '?ExtCUserEmail='+this.details.email+'&ReturnData='+this.token

        if (plan.target_url) window.location.href = plan.target_url + suffix;
      }, 100)
    }
  }
}
</script>
