import HorizontalBar from "@/assets/icons/quiz/horizontal-bar.svg";
import JumpRope from "@/assets/icons/quiz/jump-rope.svg";
import Candles from "@/assets/icons/plan/gear-candles.svg";
import Fingerboard from "@/assets/icons/plan/gear-fingerboard.svg";
import PegBoard from "@/assets/icons/quiz/pegboard.png";
import OtherTrainings from "@/assets/icons/quiz/other-trainings.svg";
import SadFace from "@/assets/icons/quiz/sad.svg";

export default [
    {
        prop: "bar",
        tab: "Horizontal Bar",
        tabMobile: "H. Bar",
        question: "Do you have a horizontal bar?",
        info: "",
        icon: HorizontalBar,
        type: "squares",
        answers: [
            { value: true, label: "Yes" },
            { value: false, label: "No" },
        ]
    },

    {
        condition: (ans) => ans.bar === false,
        prop: 'error-bar',
        question: "We are sorry, you need a horizontal bar for this plan",
        info: "",
        notification: true,
        icon: SadFace
    },

    {
        condition: (ans) => ans.bar === true,
        prop: "grip",
        tab: "Grip",
        tabMobile: "Grip",
        question: "Do you have access to grip candles or towels?",
        info: "",
        icon: Candles,
        type: "squares",
        answers: [
            { value: true, label: "Yes"},
            { value: false, label: "No" },
        ]
    },

    {
        condition: (ans) => ans.grip === false,
        prop: 'error-grip',
        question: "We are sorry, you need grip candles or towels for this plan",
        info: "",
        notification: true,
        icon: SadFace
    },

    {
        condition: (ans) => ans.grip === true,
        prop: "pegboard",
        tab: "Pegboard",
        tabMobile: "Pegboard",
        question: "Do you have a pegboard?",
        info: "",
        icon: PegBoard,
        type: "squares",
        answers: [
            { value: true, label: "Yes" },
            { value: false, label: "No" },
        ]
    },
    {
        condition: (ans) => ans.grip === true,
        prop: "fingerboard",
        tab: "Finger board",
        tabMobile: "Finger board",
        question: "Do you have a finger board?",
        info: "",
        icon: Fingerboard,
        type: "squares",
        answers: [
            { value: true, label: "Yes" },
            { value: false, label: "No" },
        ]
    },
    {
        condition: (ans) => ans.grip === true,
        prop: "qrope",
        tab: "QRope",
        tabMobile: "QRope",
        question: "Do you have a QRope?",
        icon: JumpRope,
        info: "",
        type: "squares",
        answers: [
            { value: true, label: "Yes" },
            { value: false, label: "No" },
        ]
    },
    {
        condition: (ans) => ans.grip === true,
        prop: "other-trainings",
        tab: "other trainings",
        tabMobile: "other",
        question: "Do you have other trainings, unrelated to the plan?",
        info: "",
        icon: OtherTrainings,
        type: "squares",
        answers: [
            { value: true, label: "Yes", inline :
                    {
                        prop: "other-amount",
                        question: "How many trainings?",
                            answers : [
                                {value: 1, label: "1"},
                                {value: 2, label: "2"},
                                {value: 3, label: "3"},
                                {value: 4, label: "4"},
                            ]
            } },
            { value: false, label: "No" },
        ]
    },

]
