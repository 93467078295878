<template>
  <div class="tests-chart">
    <div class="chart-legend">
      <span v-for="(text, tx) in itemLabels" :key="'label_'+tx" :style="{color:colors(tx), borderColor:borderColors(tx)}" @click="() => toggleDisabled(tx)">{{t(text.label)}}</span>
    </div>
    <svg class="chart-svg" ref="svg" :style="{height, width: '100%'}">
      <g v-if="!isNaN(width)">
        <line v-for="n in 50" :key="'grid_'+n" :x1="2+(n-1)*50" :x2="2+(n-1)*50" :y1="0" :y2="height" stroke="#dddddd" />
        <g v-for="(prop, i) in itemPaths" :key="i">
          <path v-if="!disabled[i]" :d="lineCoords(prop)" :stroke="colors(i)" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <g v-for="(tt, vx) in valueLabels" :key="'value_'+vx">
          <g v-if="!disabled[vx]">
          <text v-for="(text,vxx) in tt" :key="'valuelabel_'+vx+'_'+vxx" :y="text.y" :x="text.x" font-size="12" :fill="colors(vx)">{{t(text.label)}}</text>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>

import t from "@/lib/Locale"
const defaultColors = ["#B32318","#68d730","#004EEB","#f39012"]
const factor = 2, height = 250
export default {
  name: "TestsChart",
  props: {
    items: { type: Array },
  },

  data() {
    return {
      width: 'zxzfae',
      height,
      disabled: {},
      t,
    }
  },


  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed : {
    itemLabels() {
      const item = this.items[0]
      let labels = []
      for (let prop in item) {
        labels.push({label: prop, y: 100*factor - item[prop] *factor + 5})
      }
      return labels
    },
    valueLabels() {
      const w = isNaN(this.width) ? 50 : this.width / (this.items.length-1) - 20
      let labels = {}, x = 20
      for (let item of this.items) {
        for (let prop in item) {
          if (!labels[prop]) labels[prop] = []
          labels[prop].push({label: item[prop], x, y: 100*factor - item[prop] *factor - 5})
        }
        x += w
      }
      return Object.values(labels)
    },
    itemPaths() {
      let paths = {}, res = []
      for (let item of this.items) {
        for (let p in item) {
          if (!paths[p]) paths[p] = []
          paths[p].push(item[p] * factor)
        }
      }
      for (let p in paths) {
        res.push(paths[p])
      }
      return res;
    }
  },

  methods: {

    toggleDisabled(index) {
      this.$set(this.disabled,index, this.disabled[index] ? false : true)
    },

    onResize() {
      const r = this.$refs['svg']
      if (r) {
        let width = r.getBoundingClientRect().width
        if (width) this.width = width
      }
    },

    colors(i) {
      return defaultColors[i]
    },
    borderColors(i) {
      if (this.disabled[i]) return '#eaeaea'
      return defaultColors[i]
    },
    lineCoords(prop) {

      let s = "M 10 "+((100*factor)-(prop[0])), w = isNaN(this.width) ? 50 : this.width / prop.length - 10
      for (let d = 1; d < prop.length; d++) {
        s += " L "+(10+d*w*factor)+' '+((100*factor)-(prop[d]))+' '
      }
      s += "z"
      return s;
    }
  }
}
</script>
