<template>
  <svg class="phone-audio-bars" :style="{height, width: calcWidth}">
    <rect rx="4" :height="height*factor" width="3" :x="i*6" :y="height/2-(factor*height/2)" v-for="(factor, i) in heights" :key="i" :fill="fillColor(i)" />
  </svg>
</template>

<script>
export default {
  name: "PhoneAudioBars",
  props: {
    pos: Number,
    lines: {
      type: Number,
      default: 28
    },
    height: {
      type: Number,
      default: 32
    }
  },
  data() {
    return {
      heights:[]
    }
  },
  mounted() {
    for (let i = 0; i < this.lines; i++) {
      this.heights.push(0.2+Math.random()*0.8)
    }
  },
  computed: {
    calcWidth() {
      return this.lines * 6
    }
  },
  methods : {
    fillColor(i) {
      return (i / this.lines <  this.pos ? '#323232':'#aaaaaa')
    }
  }
}
</script>
