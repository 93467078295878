import axios from "axios";
import papa from "papaparse";
import Fito from "@/lib/fito";

const skillTestMap = [ // levels
    0, // 1
    10, // 2
    15, // 2
    21, // 3
    25, // 4
    28, // 5
    30, // 6
]

function getSkillLevel(testResult) {
    if (!testResult) testResult = 0
    testResult *= 1
    let lvl = 0
    for (let l = 0; l < skillTestMap.length; l++) {
        if (testResult >= skillTestMap[l]) lvl = l
        else break
    }
    return lvl+1
}

export async function loadTrainings(meta) {
    let data
    if (meta['custom-trainings']) {
        data = meta['custom-trainings'].replace(/\\n/g,"\n")
        data = papa.parse(data).data
    }
    else {
        const csv = await axios.get('/trainings.csv?'+Math.floor(new Date().getTime()/1000))
        data = papa.parse(csv.data).data
    }
    const columns = data.shift();
    return data.map(row => {
        let x = {};
        for (let i = 0; i < row.length; i++) {
            let v = row[i]
            if (v.length > 0 && !isNaN(v)) v *= 1
            if (v === "Yes" || v === "true") v = true
            if (v === "No" || v === "false") v = false
            x[columns[i].toLowerCase()] = v;
        }
        return x
    })
}

export function getSunday() {
    let weekDate = new Date()
    let day = weekDate.getDay()
    if (day > 0) weekDate.setDate(weekDate.getDate()-(day))
    return weekDate
}

function getTrainingDays(startDate, trainingDays) {
    let days = trainingDays[0].days
    for (let d of trainingDays) if (d.date <= startDate) days = d.days
    return days
}

export function daysInPlan(planStartDate, trainingDays, numOfWeeks = 6) {
    const lastTrainingDay = trainingDays[trainingDays.length-1].days ? trainingDays[trainingDays.length-1].days[trainingDays[trainingDays.length-1].days.length-1] : trainingDays[trainingDays.length-1]
    const lastDate = new Date(planStartDate)
    for (let i = 1; i < numOfWeeks; i++) {
        lastDate.setDate(lastDate.getDate()+7)
    }
    lastDate.setDate(lastDate.getDate()-lastDate.getDay())
    lastDate.setDate(lastDate.getDate()+lastTrainingDay)

    return Math.round((lastDate-planStartDate)/1000/60/60/24)
}

export function nextTestDate(meta, planStartDate, trainingDays, numOfWeeks = 6) {
    let d = new Date(planStartDate)
    d.setDate(d.getDate() + daysInPlan(planStartDate, getCurrentTrainingDays(meta), numOfWeeks))
    return d
}

export function hadTests(meta) {
    return meta && meta['test-results'] && meta['test-results'].length
}

export function populateDiary(trainings, planStartDate, trainingDays = [0,2,4], level = 1, numOfWeeks = 6, gear = ['bar','grip'], isCustomPlan = false, testResults = []) {

    let specialTrainingDays = trainingDays[0] && trainingDays[0].days

    if (testResults.length) testResults = testResults.map(it => it.date ? it.date : it)

    function geared(who, what) {
        if (typeof what === "string") what = [what]
        for (let need of what) {
            if (who.indexOf(need) <= -1) return false
        }
        return true
    }

    if (isCustomPlan) {
        // ... don't filter, this is a custom plan.
    }
    else {
        gear = gear.filter(it => it !== "bar" && it !== "grip")
        if (gear.length === 0) // basic gear
            trainings = trainings.filter(it => it.gear === 'Basic')
        else {
            if (geared(gear,['qrope','pegboard','fingerboard'])) trainings = trainings.filter(it => it.gear === 'Qrope,Pegboard,Fingerboard')
            else if (geared(gear,['pegboard','fingerboard'])) trainings = trainings.filter(it => it.gear === 'Pegboard,Fingerboard')
            else if (geared(gear,['qrope','fingerboard'])) trainings = trainings.filter(it => it.gear === 'Qrope,Fingerboard')
            else if (geared(gear,['qrope','pegboard'])) trainings = trainings.filter(it => it.gear === 'Qrope,Pegboard')
            else if (geared(gear,'qrope')) trainings = trainings.filter(it => it.gear === 'Qrope')
            else if (geared(gear,'pegboard')) trainings = trainings.filter(it => it.gear === 'Pegboard')
            else if (geared(gear,'fingerboard')) trainings = trainings.filter(it => it.gear === 'Fingerboard')
            else trainings = trainings.filter(it => it.gear === 'Basic')
        }

        // filter by difficulty level
        trainings = trainings.filter(t => t.level*1 === level)

    }

    let p1 = trainings.filter(t => t.type.toLowerCase() === 'p1'), p2 = trainings.filter(t => t.type.toLowerCase() === 'p2')
    let practices = []
    let tDays = [...trainingDays]
    let isPro = false
    if (level > 6) {
        isPro = true;
    }

    let numOfDays = daysInPlan(planStartDate, trainingDays, numOfWeeks)

    let date = planStartDate, daysLeft = numOfDays, isP2 = false, week = 0, skippedSunday = false, dailyTrainings
    while (daysLeft > 1) {
        if (specialTrainingDays) tDays = getTrainingDays(dateToString(date), trainingDays)

        while (tDays.indexOf(date.getDay()) === -1) { // not a practice day
            date.setDate(date.getDate()+1)
            daysLeft--
            if (date.getDay() === 0) {
                week++
                isP2 = false // reset p2 on sundays
                skippedSunday = true
            }
            if (specialTrainingDays) tDays = getTrainingDays(dateToString(date), trainingDays)
        }

        if (date.getDay() === 0 && daysLeft < numOfDays && !skippedSunday) {
            week++
            isP2 = false // reset p2 on sundays
            skippedSunday = false
        }

        if (testResults.find(it => it === dateToString(date))) {
            date.setDate(date.getDate()+1)
        }

        const difficulty = week < 1 ? 'adaptation' : (week >= 5 ? 'deload' : 'overload')

        dailyTrainings = []
        if (isPro) {
            if (week === 1 || week === 4) dailyTrainings = trainings.filter(it => isP2?it.type.toLowerCase()==='adv-power':it.type.toLowerCase()==='adv-power-2')
            else if (week === 2 || week === 5) dailyTrainings = trainings.filter(it => isP2?it.type.toLowerCase()==='adv-bursting':it.type.toLowerCase()==='adv-bursting-2')
            else dailyTrainings = trainings.filter(it => isP2?it.type.toLowerCase()==='adv-endurance':it.type.toLowerCase()==='adv-endurance-2')
        }
        else {
            dailyTrainings = isP2?p2:p1
        }

        practices.push({date: new Date(date), trainings: dailyTrainings, difficulty})
        isP2 = !isP2

        date.setDate(date.getDate()+1)
        daysLeft--
        if (specialTrainingDays) tDays = getTrainingDays(dateToString(date),trainingDays)

    }
    return practices
}

export function howManyActivitiesOfType(customRestDays, practices, type, startDate = null, endDate = null) {
    let c = 0, d,e
    if (startDate) {
        customRestDays = customRestDays.filter(it => it.date >= startDate)
        practices = practices.filter(it => dateToString(it.date) >= startDate)
    }
    if (endDate) {
        customRestDays = customRestDays.filter(it => it.date <= endDate)
        practices = practices.filter(it => dateToString(it.date) <= endDate)
    }

    d = new Date(startDate), e = new Date(endDate)
    while (d < e) {
        let day = customRestDays.find(it => it.date === dateToString(d))
        let practiceDay = practices.find(it => dateToString(it.date) === dateToString(d))
        if (day && day.activity && !practiceDay) {
            if (day.activity instanceof Array && day.activity.indexOf(type) > -1) c++
            else if (day.activity === type) c++
        }
        else if (type === 'rest' && !practiceDay) {
            c++
        }
        d.setDate(d.getDate()+1)
    }


    return c
}

export async function saveDiaryData(completedPractices = null,customRestDays = null) {
    if (completedPractices) {
        await Fito().RunAction('set-completed-practices', {practices: completedPractices})
        localStorage.setItem('completed-practices', JSON.stringify(completedPractices))
    }
    if (customRestDays) {
        await Fito().RunAction('set-custom-rest-days', {days: customRestDays})
        localStorage.setItem('custom-rest-days', JSON.stringify(customRestDays))
    }

}

export function loadDiaryData(meta = null) {
    const completedPractices = JSON.parse(localStorage.getItem('completed-practices')) ? JSON.parse(localStorage.getItem('completed-practices')) : (meta && meta['completed-practices'] ? meta['completed-practices'] : [])
    const customRestDays =  meta && meta['custom-rest-days'] ? meta['custom-rest-days'] : JSON.parse(localStorage.getItem('custom-rest-days') || '[]')
    return [completedPractices, customRestDays]
}

export function dateToString(date) {
    if (!date) return ''
    return date.getFullYear()+'-'+(date.getMonth()<9?"0":'')+(date.getMonth()+1)+'-'+(date.getDate()<10?"0":'')+date.getDate()
}

export async function saveTrainingDays(days) {
    let saved = localStorage.getItem('training-days'), today = dateToString(new Date())
    if (!saved) saved = []
    else saved = JSON.parse(saved)

    let todayIndex = saved.findIndex(it => it.date === today)
    if (todayIndex > -1) { saved.splice(todayIndex, 1) }
    saved.push({date: today, days})

    await Fito().RunAction('set-training-days', {days: saved})
    localStorage.setItem('training-days', JSON.stringify(saved))
    return saved
}

export function getCurrentTrainingDays(meta = null) {
    let trainingDays
    if (meta && meta['training-days']) {
        trainingDays = meta['training-days']
    }
    else {
        trainingDays = localStorage.getItem('training-days')
        if (trainingDays) trainingDays = JSON.parse(trainingDays)
    }
    return trainingDays?trainingDays:[0,2,4]
}

export function getCurrentSkillLevel(meta = {}) {

    let testResult
    if (meta['test-results']) testResult = meta['test-results']
    if (testResult) {
        if (testResult instanceof Array) testResult = testResult[testResult.length-1]
        return getSkillLevel(testResult.pulls)
    }
    else return 1
}

export function getLastTestResults(meta = {}) {
    let testResult
    if (meta['test-results']) testResult = meta['test-results']
    if (testResult) {
        if (testResult instanceof Array) return testResult[testResult.length-1]
        return testResult
    }
    else return null
}
