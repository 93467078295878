<template>
  <div class="plan-wrapper">
    <h3>{{t("library-list-title")}}</h3>
    <p>{{t("library-list-content")}}</p>
    <div class="plan-separator"></div>
    <div class="library-selection">
      <p :class="library==='ninja'?'active':null" @click="() => setLibrary('ninja')">{{t("Ninja Techniques")}} <span v-if="librarySize('ninja')>0">{{librarySize('ninja')}}</span></p>
      <p :class="library==='power'?'active':null" @click="() => setLibrary('power')">{{t("Power Trainings Info")}} <span v-if="librarySize('power')>0">{{librarySize('power')}}</span></p>
      <p :class="library==='motivation'?'active':null" @click="() => setLibrary('motivation')">{{t("Motivation")}} <span v-if="librarySize('motivation')>0">{{librarySize('motivation')}}</span></p>
    </div>

    <div v-if="!libraryVideosCount" class="plan-centered-info">
      <h3>{{t("There are still no videos here")}}</h3>
      <p>{{t("Check back soon as we add new content!")}}</p>
    </div>
    <div class="library-videos" v-if="libraryVideosCount > 0">
      <div class="video" v-for="(video, i) in shownVideos" :key="i">
        <Vimeo :path="video.video" />
        <h3>{{video.name}}</h3>
      </div>
      <button class="active" v-if="libraryHasMoreVideos" @click="showMoreVideos">{{t("Show more videos")}}</button>
    </div>

    <BottomNavbar />
  </div>
</template>

<script>

import t from '@/lib/Locale';
import BottomNavbar from "@/components/plan/BottomNavbar";
import {loadTrainings} from "@/lib/DiaryUtils";
import Vimeo from "@/components/ui/widgets/Vimeo";
import {getLoginDetails} from "@/lib/PlanUtils";

export default {
  name: "LibraryList",
  components: {Vimeo, BottomNavbar},
  data() {
    return {
      t,
      library: "ninja",
      videos: [],
      show: 10,
    }
  },
  async mounted() {
    const login = await getLoginDetails('/plan/login', null, true)
    const trainings = await loadTrainings(login.meta);
    this.initVideos(trainings)

  },
  computed : {
    libraryVideosCount() {
      return this.libraryVideos(this.library).length
    },
    shownVideos() {
      return this.libraryVideos(this.library).slice(0, this.show)
    },
    libraryHasMoreVideos() {
      return this.show < this.librarySize(this.library)
    }
  },
  methods : {
    showMoreVideos() {
      this.show = this.show + 10
    },
    librarySize(library) {
      return this.videos.filter(it => it.library === library).length
    },
    libraryVideos(library) {
      return this.videos.filter(it => it.library === library)
    },
    setLibrary(type) {
      if (this.library !== type) {
        this.library = type
        this.show = 10
      }
    },
    initVideos(trainings) {
      let v = [];
      let vids = {}
      for (let t of trainings) {
        if (t.video && !vids[t.video]) {
          v.push({
            name: t.training,
            details: t.details,
            library: t.library||'power',
            video: t.video
          })
          vids[t.video] = true
        }
      }
      this.videos = v
    }
  }
}
</script>
