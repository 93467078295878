<template>
  <div class="weekday-selector-wrapper">

  <div class="weekday-selector">
    <div v-for="(day, i) in days" :key="i" :class="isSelected(i)?'active':null" @click="() => select(i)">
      <p>{{t(day)}}</p>
      <div class="active-indicator" v-if="isSelected(i)">{{t("Practice")}} <img :src="FireIcon" class="fire-icon" /></div>
    </div>
  </div>
  </div>
</template>

<script>

import t from '@/lib/Locale';
import FireIcon from "@/assets/icons/fire.svg";
let days = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]

export default {
  name: "WeekdaySelector",
  props: {
    selected: Array,
    onSelect: Function,
  },
  data() {
    return {
      t,
      FireIcon,
      days,
    }
  },
  methods : {
    isSelected(day) {
      return this.selected.indexOf(day) > -1
    },
    select(day) {
      if (this.onSelect) this.onSelect(day)
    }
  }
}
</script>
