<template>
  <div class="plan-wrapper">
    <div class="success-bar" v-if="success"><p>{{success}}</p></div>
      <Flexy class="profile-meta">
          <div class="flex-1">
            <h2>{{first_name + ' '+last_name}}</h2>
            <p>{{email}}</p>
          </div>
      </Flexy>
      <router-link class="plan-btn" to="/plan/profile/edit-details">{{t('Edit Profile')}} <img :src="EditIcon" /></router-link>
    <div class="plan-separator" />

    <NinjaPointsBar :points="completedPractices.length * 30" />


    <LinksPanel :items="links" />
    <div class="plan-centered-info">
      <router-link to="/plan/logout" class="primary">{{t("Log Out")}}</router-link>
    </div>

    <BottomNavbar />

  </div>
</template>

<script>
import t from '@/lib/Locale';
import Fito from '@/lib/fito';
import Flexy from "@/components/ui/widgets/Flexy";
import BottomNavbar from "@/components/plan/BottomNavbar";
import NinjaPointsBar from "@/components/plan/NinjaPointsBar";
import LinksPanel from "@/components/plan/LinksPanel";
import EditIcon from "@/assets/icons/edit.svg";
import CalendarIcon from "@/assets/icons/plan/menu-calendar.svg";
import CreditCardIcon from "@/assets/icons/credit-card.svg";
import BenchIcon from "@/assets/icons/plan/bench.svg";
import SupportIcon from "@/assets/icons/support.svg";
import {loadDiaryData} from "@/lib/DiaryUtils";

export default {
  name: "MyProfile",
  components: {LinksPanel, NinjaPointsBar,BottomNavbar, Flexy},
  data() {
    return {
      EditIcon,
      links : [],
      first_name: '', last_name: '', email: '',
      login : {},
      expired: false,
      success: '',
      completedPractices : [],
    }
  },
  async mounted() {
    const login = await Fito().Login.CheckLogin();
    if (!login) await this.$router.push('/plan/login');

    this.links = [
      {to: "/plan/profile/trainings", label: "Training Days", details: t("Set Training Days"), icon: CalendarIcon},
      {to: "/plan/profile/gear", label: "Gear", details: t("Set your gear"), icon: BenchIcon},
      {to: "/plan/profile/account-details", label: "Account Details", details: t("Receipts, account status"), icon: CreditCardIcon},
      {to: "/plan/profile/contact-us", label: "Contact Us", details: t("We are always here to service you"), icon: SupportIcon},
    ]

    this.login = login
    this.expired = login.status === 'expired'
    this.first_name = login.subscriber.first_name
    this.last_name = login.subscriber.last_name
    this.email = login.subscriber.email

    const [completedPractices, ] = loadDiaryData(login.meta)
    this.completedPractices = completedPractices

  },

  methods: {
    t,
  },

  computed : {

  }
}
</script>
