<template>
  <div class="plan-panel gear">
    <Flexy v-for="(item, i) in items" :key="i">
      <Checkbox :checked="selected.indexOf(item.id)>-1" :on-select="() => onSelect(item.id)" />
      <div class="item-inner" @click="onSelect(item.id)">
        <img :src="item.icon" />
        <span>{{item.name}}</span>
      </div>
    </Flexy>
  </div>
</template>

<script>
import t from '@/lib/Locale'
import Flexy from "@/components/ui/widgets/Flexy";
import CheckboxIcon from "@/assets/icons/plan/checkbox-off.svg";
import CheckboxOnIcon from "@/assets/icons/plan/checkbox-on.svg";
import Checkbox from "@/components/ui/widgets/Checkbox";

export default {
  name: "GearSelectionPanel",
  components: {Checkbox, Flexy},
  props: {
    items: Array,
    selected: Array,
    onSelect: Function,
  },
  data() {
    return {
      CheckboxIcon, CheckboxOnIcon,
      t,
    }
  }
}
</script>
