<template>
  <div class="plan-panel links">
    <Flexy v-for="(item, i) in items" :key="i">
      <router-link :to="item.to" class="flex-1">
        <div class="first">
          <h4><img v-if="item.icon" class="link-icon" :src="item.icon" /> {{t(item.label)}}</h4>
          <span v-if="item.details">{{item.details}}</span>
        </div>
        <img :src="ChevronIcon" class="chevron" />
      </router-link>
    </Flexy>
  </div>
</template>

<script>
import t from '@/lib/Locale'
import Flexy from "@/components/ui/widgets/Flexy";
import ChevronIcon from "@/assets/icons/chevron-left.svg";

export default {
  name: "LinksPanel",
  components: {Flexy},
  props: {
    items: Array
  },
  data() {
    return {
      ChevronIcon,
      t,
    }
  }
}
</script>
