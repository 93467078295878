<template>
  <div :class="cls">
    <div class="progress-bar-inner" :style="{width}"></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: ["done", "small"],
  computed: {
    cls() {
      return 'progress-bar'
       + (this.small ? ' small':'')
       + (this.done>=1 ? ' done':'')
    },
    width() {
      if (!isNaN(this.done*1)) {
        return this.done*100+'%'
      }
      return this.done
    }
  }
}
</script>
