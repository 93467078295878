<template>
  <div class="panel-wrapper">
    <div class="plan-centered-info">{{t("Logging you out...")}}</div>
  </div>
</template>

<script>
import t from "@/lib/Locale";
import Fito from '@/lib/fito';
import Logo from '@/assets/images/gorilla-logo.svg';


export default {
  name: "Login",

  data : function() {
    return {
      Logo,
      error: '',
      forgot : false,
      phoneRecovery: false,
      trying: false,
      expired: false,
      recoverySent: false
    }
  },

  async mounted() {
    try {
      await Fito().Login.Logout()
    }
    catch (e) {
      console.log({e})
    }

    setTimeout(() => {
      localStorage.removeItem('login-token')
      this.$router.push("/")
    })
  },

  methods: {
    t,
  },
}
</script>
