import Fito from "@/lib/fito";
import {
    dateToString, daysInPlan,
    getCurrentSkillLevel,
    getCurrentTrainingDays,
    loadTrainings,
    populateDiary
} from "@/lib/DiaryUtils";
import t from "@/lib/Locale";
import BarIcon from "@/assets/icons/plan/gear-bar.svg";
import CandlesIcon from "@/assets/icons/plan/gear-candles.svg";
import PegboardIcon from "@/assets/icons/plan/gear-pegboard.svg";
import FingerboardIcon from "@/assets/icons/plan/gear-fingerboard.svg";
import RopeIcon from "@/assets/icons/plan/gear-rope.svg";

export const gearItems = [
    { id:"bar", name: t("Horizontal Bar"), icon: BarIcon },
    { id:"grip", name: t("Grip Candles / Towels"), icon: CandlesIcon },
    { id:"pegboard", name: t("Pegboard"), icon: PegboardIcon },
    { id:"fingerboard", name: t("Fingerboard"), icon: FingerboardIcon },
    { id:"qrope", name: t("QROPE"), icon: RopeIcon },
]

let resettingGear = false


export async function getLoginDetails(redirectIfLoggedOut = false, redirectIfLoggedIn = false, force = false) {
    const login = await Fito().Login.CheckLogin(force);
    if (!login.subscriber) {
        if (redirectIfLoggedOut) window.location.href = redirectIfLoggedOut
        return false;
    }
    else {
        if (redirectIfLoggedIn) window.location.href = redirectIfLoggedIn
    }
    return login
}

export function getDailyWarmups(skillLevel) {
    let advanced = skillLevel >= 4
    return [
        {training: t("warmup-1-title"), thumb: "https://i.vimeocdn.com/video/1572912217-8d312148980fef9e98044364bfda7752c3305a8f0800a756ba94aecb77f2157d-d_236x133", video: 776674540 },
        advanced ? {training: t("warmup-2-advanced-title"), details : t("warmup-2-advanced-details"), thumb: "https://i.vimeocdn.com/video/1572930405-1be3ed5e00244ebdc3de30c1feb3d2dc7dcc52bf69d35c5b303089441c898f56-d_236x133", video: 776681885} : {training: t("warmup-2-beginner-title"), details : t("warmup-2-beginner-details"), thumb: "https://i.vimeocdn.com/video/1572929869-a2322118647425a3126de15dc570020a600e56c3de17c90c5c01290ff22c27c8-d_236x133", video: 776681597},
        advanced ? {training: t("warmup-3-advanced-title"), details : t("warmup-3-advanced-details"), thumb: "https://i.vimeocdn.com/video/1572928105-a1400fb3864aceee74b906ecd8dade0c4386c1f376e50b420af2f4739c7e53cd-d_236x133", video: 776680567} : {training: t("warmup-3-beginner-title"), details : t("warmup-3-beginner-details"), thumb: "https://i.vimeocdn.com/video/1572928536-8eb0267e6e7a743156549e56aead0e8ae08a9b62915ad2a0c190fb27fc411f43-d_236x133", video: 776680827}
    ]
}

export async function getSubscriberSchedule(subscriber, meta = null) {
    const startDate = getPlanStartDate(subscriber, meta)
    const trainings = await loadTrainings(meta), gear = await getPlanGear(subscriber, meta)
    return populateDiary(trainings, startDate, getCurrentTrainingDays(meta), getCurrentSkillLevel(meta), 666, gear, !!meta['custom-trainings'], meta['test-results'])
}

export async function getPlanGear(subscriber, meta = null) {
    let gear
    if (meta && meta['gear']) {
        gear = meta['gear']
    }
    if (!gear) {
        const answers = JSON.parse(localStorage.getItem('QuizAnswers'))
        if (answers) {
            gear = []
            for (let ans in answers) if (gearItems.find(it => it.id === ans && answers[ans])) gear.push(ans)
            if (!resettingGear) {
                resettingGear = true
                await Fito().RunAction('set-gear', { gear })
            }
        }
    }
    return gear
}

export function getPlanStartDate(subscriber, meta = null) {
    let startDate = new Date(subscriber.subscription_date)
    startDate.setHours(0); startDate.setMinutes(0); startDate.setSeconds(0)

    let savedDate
    if (meta && meta['plan-start-date']) {
        savedDate = meta['plan-start-date']
    }
    if (savedDate) {
        savedDate = new Date(savedDate)
        if (savedDate < startDate) {
            return startDate
        }
        return savedDate
    }

    if (meta && meta['test-results']) {
        let testDates = meta['test-results'].map(it => new Date(it.date)).sort((a, b) => a > b ? 1 : -1)
        if (testDates.length) startDate = new Date(testDates[0])
    }

    return startDate
}
export function getPlanCurrentPeriodDate(subscriber, meta = null) {
    let d = getPlanStartDate(subscriber, meta)

    if (meta && meta['test-results']) {
        let testDates = meta['test-results'].map(it => new Date(it.date)).sort((a, b) => a < b ? 1 : -1)
        if (testDates.length) d = new Date(testDates[0])
    }

    return d
}
export function getPlanNextTestDate(subscriber, meta = null) {
    let d = getPlanCurrentPeriodDate(subscriber, meta)
    const days = daysInPlan(d, )
    d.setDate(d.getDate()+ days)
    return d
}

export async function setTestScore(subscriber, date, pulls, hold_both, hold_right, hold_left) {
    await Fito().RunAction('submit-test', {date, pulls, hold_both, hold_right, hold_left})
    localStorage.setItem('skill-test-score', JSON.stringify({
        pulls, hold_both, hold_right, hold_left
    }))
}

export async function setGear(subscriber, gear) {
    await Fito().RunAction('set-gear', { gear })
}

export async function setCurrentTraining(training, day = null, trainings = null, warmups = null) {
    localStorage.setItem('current-training', JSON.stringify(training))
    if (day) localStorage.setItem('current-date', day)
    if (trainings) localStorage.setItem('day-trainings', JSON.stringify(trainings))
    if (warmups) localStorage.setItem('day-warmups', JSON.stringify(warmups))
}

export function planWeek(weekDate, planStartDate) {
    const weekDiff = ((weekDate - planStartDate)/1000/60/60/24/7)
    return (Math.round(weekDiff)+1)
}

export async function setPlanStartDate(date) {
    await Fito().RunAction("set-plan-start-date", {date: dateToString(date)})
}


export async function clearLocalCaches() {
    console.log("Clearing local caches")
    localStorage.removeItem('gear')
    localStorage.removeItem('skill-test-score')
    localStorage.removeItem('completed-practices')
    localStorage.removeItem('custom-rest-days')
}
