<template>
  <div class="stats-row">
    <div class="stat">
      <div class="stat-title">
        <img :src="ScoreIcon" /> {{t("Ninja Points")}}
      </div>
      <h1>{{points}}<small>/{{level*pointsPerLevel}}</small></h1>

      <div class="progress-wrapper">
        <ProgressBar :done="done" />
        <span>{{t("Level {0}", level)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';

import ScoreIcon from '@/assets/icons/plan/badge-score.svg';
import ProgressBar from "@/components/ui/widgets/ProgressBar";


export default {
  name: "NinjaPointsBar",
  components: {ProgressBar},
  props: {
    pointsPerLevel : { type: Number, default: 100 },
    points: Number,
  },
  data() {
    return {
      t,
      ScoreIcon,
    }
  },
  computed : {
    done() {
      return (this.points % this.pointsPerLevel) / this.pointsPerLevel
    },
    level() {
      return Math.floor(this.points/this.pointsPerLevel)+1
    }
  }
}
</script>
