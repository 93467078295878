<template>
  <div :class="'navbar'+(loggedIn?' logged-in':'')+(insidePage?' inside-page':'')" v-if="!isLanding">
    <div v-if="insidePage" class="inside">
      <router-link :to="insidePage" class="arrow-holder">
        <img :src="ArrowBack" class="back-icon" />
      </router-link>
      <h3><span>{{title}}</span></h3>
    </div>
    <div class="navbar-inner" v-if="!insidePage">
      <div class="navbar-logo">
        <router-link :to="logoLink"><img :src="logo" /><span>{{t('navbar-logo-suffix')}}</span></router-link>
      </div>
    </div>
  </div>
</template>

<script>

import logo from '@/assets/images/navbar-logo.svg';
import ArrowBack from '@/assets/icons/arrow-back.svg';

import t from '@/lib/Locale';

export default {
  name: "Navbar",
  props: ['isLanding','loggedIn','subscriber','onLogout','insidePage'],
  data() {
    return {
      logo, ArrowBack,

      menuOpen: false
    }
  },

  computed : {
    logoLink() {
      return this.loggedIn ? '/plan/home' : '/'
    },
    title() {
      return this.$route.meta && this.$route.meta.title ? t(this.$route.meta.title) : ''
    }
  },

  methods : {
    t,
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
    closeMenu() { this.menuOpen = false }
  }
}
</script>
